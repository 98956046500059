"use client";
import React from "react";
import CatalogSection from "../../components/Molecules/CatalogSection";

/**
 * @typedef {import("@prismicio/client").Content.CatalogSlice} CatalogSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CatalogSlice>} CatalogProps
 * @param { CatalogProps }
 */
const Catalog = ({ slice }) => (
    <section>
        <CatalogSection data={slice} />
        <style jsx>{`
            section {
                margin: 4em auto;
                text-align: center;
            }
        `}</style>
    </section>
);

export default Catalog;
