import { ENGLISH } from "../../../public/Constants/EnumConstants";
export const BACKGROUND_IMAGE_CONTAINER_CLASS =
    " bg-no-repeat bg-cover bg-center";
const STYLING_SESSION_LEFT_ALIGNMENT =
    "px-4 lg:px-20 py-10 mr-0 lg:mr-[20%] xl:mr-[40%] text-center md:text-left";
const STYLING_SESSION_RIGHT_ALIGNMENT =
    "px-4 lg:px-20 py-10 ml-0 lg:ml-[20%] xl:ml-[40%] text-center lg:text-right";
export const getStylingSessionInnerContainerClass = (locale) => {
    return locale === ENGLISH
        ? STYLING_SESSION_LEFT_ALIGNMENT
        : STYLING_SESSION_RIGHT_ALIGNMENT;
};
