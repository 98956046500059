"use client";
import React from "react";
import HowToWearThatSection from "../../components/Molecules/HowToWearThatSection";

const HowToWearThat = ({ slice }) => (
    <section>
        <HowToWearThatSection data={slice} />
        <style jsx>{`
            section {
                margin: 4em auto;
                text-align: center;
                max-width: 1440px;
            }
            @media screen and (max-width: 500px) {
                section {
                    margin: 3em auto;
                }
            }
        `}</style>
    </section>
);

export default HowToWearThat;
