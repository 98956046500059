import { getCountryDetails } from "../../../public/Constants/CountriesList";

export const updateDescription = (paragraph, userCountry) => {
    if (paragraph?.includes("SAR 107") && userCountry) {
        return paragraph?.replace(
            /SAR 107/g,
            `${userCountry?.currency} ${userCountry?.price}`
        );
    }
    return paragraph;
};
