import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { PLAYFAIR_2XL_MEDIUM } from "./constants";

const Playfair2xlMedium = ({ text, simpleText, customStyle }) => {
    const textComponent = !simpleText ? (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <h1 className={PLAYFAIR_2XL_MEDIUM + customStyle}>
                        {children}
                    </h1>
                ),
                heading2: ({ children }) => (
                    <h2 className={PLAYFAIR_2XL_MEDIUM + customStyle}>
                        {children}
                    </h2>
                ),
                paragraph: ({ children }) => (
                    <p className={PLAYFAIR_2XL_MEDIUM + customStyle}>
                        {children}
                    </p>
                ),
            }}
        ></PrismicRichText>
    ) : (
        <p className={PLAYFAIR_2XL_MEDIUM + customStyle}>{simpleText}</p>
    );
    return textComponent;
};

export default Playfair2xlMedium;
