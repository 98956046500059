import React from "react";

import {
    HOW_TO_WEAR_TAHT_INNER_CONTAINER_ONE,
    HOW_TO_WEAR_TAHT_INNER_CONTAINER_TWO,
} from "./constants";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";
import SectionHeading from "../../Atoms/Typography/SectionHeading";
import {
    IMAGE_BACKGROUND_STYLE,
    ORDER_A_LOOK,
} from "../../../public/Constants/EnumConstants";
import PrimaryButtonECommerceOnly from "../../Atoms/Buttons/PrimaryButtonECommerceOnly";

const CatalogSection = ({ data }) => {
    return (
        <div>
            <div className={HOW_TO_WEAR_TAHT_INNER_CONTAINER_ONE}>
                <SectionHeading text={data?.primary?.title} />
            </div>

            <div className={HOW_TO_WEAR_TAHT_INNER_CONTAINER_TWO}>
                <ImageAtom
                    image={data?.primary?.desktop_image}
                    style={IMAGE_BACKGROUND_STYLE}
                />
            </div>
            <PrimaryButtonECommerceOnly
                text={data?.primary?.button_text}
                href={`${process.env.OLD_WEB_URL}${ORDER_A_LOOK}`}
            />
        </div>
    );
};

export default CatalogSection;
