import React, { useState, useEffect } from "react";
import { CHECKBOX_TYPE } from "../../../public/Constants/EnumConstants";
import NewCheckbox from "../../Atoms/Inputs/NewCheckbox";
import { CHECKOUT, CHECKOUT_LABEL } from "../SurveyPaymentForm/constants";
import { useForm } from "react-hook-form";
import { DEFAULT_VALUES, PAYMENT_CARD_FORM_SCHEMA } from "./FormSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorTextWithIcon from "../../Atoms/Typography/ErrorTextWithIcon/ErrorTextWithIcon";
import { useSelector } from "react-redux";
import ApplePayButton from "./ApplePayButton";

const GriftcardApplePayForm = ({ getCompleteData }) => {
    const [payButtonDisabled, setPayButtonDisabled] = useState(true);
    const [completeData, setCompleteData] = useState(null);

    const { userCountry } = useSelector((state) => state?.country);

    const {
        register,
        formState: { errors },
        watch,
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(PAYMENT_CARD_FORM_SCHEMA),
    });

    useEffect(() => {
        if (watch(CHECKOUT)) {
            setPayButtonDisabled(false);
            // Call getCompleteData with an empty object for token if token is not available
            const data = getCompleteData({});
            console.log('in apple form',{data});
            setCompleteData(data);
        } else {
            setPayButtonDisabled(true);
        }
    }, [watch(CHECKOUT)]);

    return (
        <div className="pl-8">
            <NewCheckbox
                errors={errors}
                field={CHECKOUT}
                label={CHECKOUT_LABEL}
                type={CHECKBOX_TYPE}
                register={register}
            />
            {payButtonDisabled && (
                <ErrorTextWithIcon text={"Please Check this box"} />
            )}
            <div className="w-[220px] flex justify-center survey-confirm-payment-button">
                <ApplePayButton
                    currency={userCountry?.currency}
                    country={userCountry?.flag}
                    disabled={payButtonDisabled}
                    completeData={completeData} // Pass the completeData to ApplePayButton
                />
            </div>
        </div>
    );
};

export default GriftcardApplePayForm;
