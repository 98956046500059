import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Montserrat_SMALL_MEDIUM } from "./constants";

const MonsterratSmallMedium = ({
    text,
    simpleText,
    alignment,
    customStyle,
}) => {
    const textComponent = !simpleText ? (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <p className={Montserrat_SMALL_MEDIUM + customStyle}>
                        {children}
                    </p>
                ),
                paragraph: ({ children }) => (
                    <p className={Montserrat_SMALL_MEDIUM + customStyle}>
                        {children}
                    </p>
                ),
            }}
        ></PrismicRichText>
    ) : (
        <p className={Montserrat_SMALL_MEDIUM + customStyle}>{simpleText}</p>
    );
    return textComponent;
};

export default MonsterratSmallMedium;
