"use client";
import React from "react";
import AboutUsBannerSection from "../../components/Molecules/AboutUsBannerSection";
import { VERSION_1 } from "../../public/Constants/EnumConstants";
import AboutUsBannerSectionVersion1 from "../../components/Molecules/AboutUsBannerSectionVersion1";

const versionComponents = {
    [VERSION_1]: AboutUsBannerSectionVersion1,
};

const AboutUsBanner = ({ slice }) => {
    const VersionComponent =
        versionComponents[slice?.variation] || AboutUsBannerSection;

    return (
        <section>
            <VersionComponent data={slice} />
            <style jsx>{`
                section {
                    margin: auto;
                    text-align: center;
                }
            `}</style>
        </section>
    );
};

export default AboutUsBanner;
