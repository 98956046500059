import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { CARD_DESCRIPTION_CLASS } from "./constants";

const Playfair4xlRegular = ({ text, alignment }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <h1 className={alignment + CARD_DESCRIPTION_CLASS}>
                        {children}
                    </h1>
                ),
                paragraph: ({ children }) => (
                    <h1 className={alignment + CARD_DESCRIPTION_CLASS}>
                        {children}
                    </h1>
                ),
            }}
        ></PrismicRichText>
    );
};

export default Playfair4xlRegular;
