import React, { Suspense } from "react";

import { BREAK_POINT_WIDTH, CONTAINER_CLASS } from "./constants";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";

import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import WearthatLoader from "../../Atoms/WearthatLoader";

const AboutUsBannerSection = ({ data }) => {
    const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);

    return (
        <div className={CONTAINER_CLASS}>
            <Suspense fallback={<WearthatLoader />}>
                <ImageAtom
                    image={
                        !isBreakpoint
                            ? data?.primary?.hero_image
                            : data?.primary?.hero_image_mobile
                    }
                />
            </Suspense>
        </div>
    );
};

export default AboutUsBannerSection;
