"use client";
import React, { useState } from "react";
import Image from "next/image";
import ImageAtom from "../ImageAtom/ImageAtom";
import { DownArrow } from "../../../public/Theme";
import TypographyMontserrat from "../TypographyMontserrat/";
import {
    ACCORDION_DETAILS,
    ACCORDION_EXPANDED_DATA,
    ACCORDION_SUMMARY,
    IMAGE_CONTAINER,
    ARROW_CONTAINER,
    QUESTION_TEXT_CONTAINER,
    ARROW_CLASS,
    ARROW_ROTATE,
    ACCORDION_CLOSE_TRANSITION,
    ACCORDION_OPEN_TRANSITION,
    HandleToggle,
} from "./constants";
import { sendGTMEvent } from "@next/third-parties/google";

const Accordion = ({
    question,
    answer,
    id,
    image,
    isGTMEvent = false,
    gtmObj,
}) => {
    const [accordionOpen, setAccordionOpen] = useState(false);
    return (
        <div
            className={
                accordionOpen === id
                    ? ACCORDION_DETAILS + " !border-Tooltip"
                    : ACCORDION_DETAILS
            }
            onClick={() => {
                if (isGTMEvent) {
                    sendGTMEvent(gtmObj);
                }

                HandleToggle(id, accordionOpen, setAccordionOpen);
            }}
        >
            <div className={ACCORDION_SUMMARY}>
                <div className={QUESTION_TEXT_CONTAINER}>
                    <TypographyMontserrat
                        align={"left"}
                        children={question}
                        fontWeight={"medium"}
                        size={"xl"}
                    />
                </div>
                <div className={ARROW_CONTAINER}>
                    <Image
                        className={
                            ARROW_CLASS +
                            (accordionOpen === id ? ARROW_ROTATE : null)
                        }
                        width={20}
                        height={20}
                        alt="DownArrow"
                        src={DownArrow}
                    />
                </div>
            </div>
            <div
                onClick={(e) => e.stopPropagation()}
                className={
                    ACCORDION_EXPANDED_DATA +
                    (accordionOpen === id
                        ? ACCORDION_OPEN_TRANSITION
                        : ACCORDION_CLOSE_TRANSITION)
                }
            >
                <TypographyMontserrat
                    align={"left"}
                    children={answer}
                    fontWeight={"light"}
                    size={"base"}
                />
                <div className={IMAGE_CONTAINER}>
                    {image && accordionOpen ? (
                        <ImageAtom image={image} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
