import React from "react";
import { useRouter } from "next/navigation";
import {
    BACKGROUND_IMAGE_CONTAINER_CLASS,
    getStylingSessionInnerContainerClass,
} from "./constants";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton/PrimaryButton";
import StylingSessionDescription from "../../Atoms/Typography/StylingSessionDescription";
import SectionHeadingWithAligments from "../../Atoms/Typography/SectionHeadingWithAligments/SectionHeadingWithAligments";
import { STYLING_SESSION_ENDPOINT } from "../../../public/Constants/EnumConstants";

const StylingSessionSection = ({ data }) => {
    const router = useRouter();
    return (
        <div
            className={BACKGROUND_IMAGE_CONTAINER_CLASS}
            style={{ backgroundImage: `url(${data?.background_image?.url})` }}
        >
            <div
                className={getStylingSessionInnerContainerClass(router?.locale)}
            >
                <SectionHeadingWithAligments text={data?.title} />
                <StylingSessionDescription
                    text={data?.description}
                    alignment={router?.locale}
                />
                <PrimaryButton
                    text={data?.button_text}
                    loader={data?.loader}
                    href={STYLING_SESSION_ENDPOINT}
                />
            </div>
        </div>
    );
};

export default StylingSessionSection;
