"use client";
import React, { useState } from "react";
import Slider from "react-slick";
import dynamic from "next/dynamic";

import {
    SETTINGS,
    NEWS_SECTION_CONTAINER_ONE,
    NEWS_SECTION_CONTAINER_TWO,
} from "./constants";
import SessionDescription from "../../Atoms/Typography/SessionDescription";

const NewsCard = dynamic(() => import("../../Molecules/NewsCard/NewsCard"));
const SectionHeading = dynamic(
    () => import("../../Atoms/Typography/SectionHeading")
);

const NewsSection = ({ data }) => {
    return (
        <div>
            <div className={NEWS_SECTION_CONTAINER_ONE}>
                <SectionHeading text={data?.primary?.title} />
                <SessionDescription text={data?.primary?.description} />
            </div>
            <div className={NEWS_SECTION_CONTAINER_TWO}>
                <Slider {...SETTINGS}>
                    {data?.items?.map((item, index) => {
                        return (
                            <NewsCard
                                key={index}
                                index={index}
                                logo={item?.logo}
                                image={item?.article_image}
                                mobileImage={item?.mobile_article_image}
                                description={item?.highlight_text}
                                link={item?.article_link?.url}
                            />
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default NewsSection;
