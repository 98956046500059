"use client";
import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";

import Image from "next/image";
import { VideoIcon } from "../../../public/Theme";

const HlsJsPlayer = ({
    autoPlay = true,
    source,
    containerClassName,
    videoClassName,
    videoPlaceHolder,
    preload = "auto",
    muted = true,
}) => {
    const videoRef = useRef();
    const [showButtons, setShowButtons] = useState({
        play: autoPlay ? true : false,
    });
    useEffect(() => {
        if (Hls?.isSupported()) {
            var hls = new Hls();
            hls?.loadSource(source);
            hls?.attachMedia(videoRef?.current);
        } else if (
            videoRef?.current?.canPlayType("application/vnd.apple.mpegurl")
        ) {
            videoRef.current.src = source;
        }
    }, []);
    return (
        <div className={containerClassName}>
            <video
                ref={videoRef}
                autoPlay={autoPlay}
                className={videoClassName}
                muted={muted}
                loop
                playsInline
                preload={preload}
                poster={videoPlaceHolder}
            >
                <source type="application/x-mpegURL"></source>
            </video>

            <div
                className={
                    " absolute top-0 right-0 left-0 bottom-0 flex flex-col my-auto justify-between cursor-pointer"
                }
                onClick={() => {
                    if (showButtons?.play) {
                        videoRef?.current?.pause();
                        videoRef?.current?.classList?.add("opacity-80");
                        setShowButtons({ ...showButtons, play: false });
                    } else {
                        videoRef?.current?.play();
                        videoRef?.current?.classList?.remove("opacity-80");
                        setShowButtons({ ...showButtons, play: true });
                    }
                }}
            >
                <div
                    className={
                        "flex-col items-center justify-center h-max w-max m-auto"
                    }
                >
                    {!showButtons?.play && (
                        <Image
                            src={VideoIcon}
                            alt="play button icon"
                            width={40}
                            height={40}
                        />
                    )}
                </div>

                <div />
            </div>
        </div>
    );
};

export default HlsJsPlayer;
