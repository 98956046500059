"use client";
import React from "react";
import NewsSection from "../../components/Organisms/NewsSection";
import BrandSection from "../../components/Organisms/BrandSection";

const News = ({ slice }) => {
    let variant = <NewsSection data={slice} />;
    if (slice?.variation === "brands") {
        variant = <BrandSection data={slice} />;
    }
    return (
        <section>
            {variant}

            <style jsx>{`
                section {
                    margin: 7.5em auto;
                    text-align: center;
                }
                @media screen and (max-width: 500px) {
                    section {
                        margin: 5em auto;
                    }
                }
            `}</style>
        </section>
    );
};

export default News;
