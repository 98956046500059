"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton/PrimaryButton";
import { useForm, useFieldArray } from "react-hook-form";
import { InputField, FieldArrayInput } from "../../Atoms/Inputs/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import SectionHeading from "../../Atoms/Typography/SectionHeading";
import StylingSessionDescription from "../../Atoms/Typography/StylingSessionDescription";
import {
    DropdownField,
    FieldArrayDropdown,
} from "../../Atoms/Inputs/DropdownField";
import Checkbox from "../../Atoms/Inputs/Checkbox";
import { useRouter } from "next/navigation";
import TextArea from "../../Atoms/Inputs/TextArea";
import ErrorToast from "../../Atoms/Popups/ErrorToast";
import { BinIcon } from "../../../public/Theme";
import * as Sentry from "@sentry/nextjs";

import {
    FORMS_CONTAINER,
    FORM_HEADING,
    ROW_CONTAINER,
    CHANNEL_NAME_FIELD,
    CHANNEL_HANDLE_FIELD,
    FIRST_NAME_FIELD,
    LAST_NAME_FIELD,
    EMAIL_FIELD,
    COUNTRY_FIELD,
    REASON_FIELD,
    TERTIARY_BUTTON,
    ADD_MORE_BUTTON_CONTAINER,
    SOCIAL_CHANNELS_FIELD,
    CHANNEL_HANDLE_FIELD_ERROR,
    CHECK_BOX_FIELD,
    FIELD_CONTAINER,
    REASON_FIELD_CONTAINER,
    SOCIAL_CHANNEL_HEADING,
    CHECKBOX_CONTAINER,
    SOCIAL_CHANNEL_FIELD,
    PRIMARY_BUTTON_CONTAINER,
    REASON_FIELD_HEIGHT,
    SECTION_CONTAINER,
    SECTION_SUB_CONTAINER,
    SECTION_HEADING,
    CHANNEL_HANDLE_TEXT,
    CHANNEL_NAME_TEXT,
    FORM_ID,
    SOCIAL_FIELD_ROW,
    SOCIAL_CHANNEL_URL_FIELD,
    SOCIAL_FORM_CONTAINER,
    TERMS_AND_CONDITIONS_CLASS,
    BIN_ICON_CLASS,
} from "./constants";
import {
    AMBASSADOR_REGISTRATION_SUCCESS_PAGE,
    API_REGISTER_AMBASSADOR,
    BUTTON_TYPE,
    CHECKBOX_TYPE,
    COUNTRY_LIST,
    EMAIL_TYPE,
    FOUR_HUNDRED,
    SIX,
    SUBMIT_TYPE,
    TEXT_TYPE,
    WIDTH_200_PX,
} from "../../../public/Constants/EnumConstants";
import { DEFAULT_VALUES, getFormSchema } from "./FormSchema";
import { InputErrorMessage } from "../../Atoms/Typography/InputErrorMessage";
import TertiaryButtonSmallSize from "../../Atoms/Buttons/TertiaryButtonSmallSize/TertiaryButtonSmallSize";
import ProgressLoader from "../../Atoms/ProgressLoader";
import { sendGTMEvent } from "@next/third-parties/google";
import { GoogleTagManagerEvents } from "../../../common";

const AmbassadorRegistrationForm = ({ data, items }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [fromSubmissionInProgress, setFromSubmissionInProgress] =
        useState(false);
    const router = useRouter();
    const schema = getFormSchema(router?.locale);
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(schema),
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: SOCIAL_CHANNELS_FIELD,
    });

    let socialMediaChannelsList = [];
    items?.map((item) => {
        socialMediaChannelsList.push(item?.social_channel_options);
    });

    const termsAndConditions = (label) => {
        return (
            <div className={label}>
                <div>{data?.agreement_statement?.[0]?.text}</div>
                <a
                    href={
                        data?.terms_and_conditions_link_text?.[0]?.spans?.[0]
                            ?.data?.url
                    }
                >
                    <div className={TERMS_AND_CONDITIONS_CLASS}>
                        {data?.terms_and_conditions_link_text?.[0]?.text}
                    </div>
                </a>
            </div>
        );
    };
    const onsubmit = async (e) => {
        sendGTMEvent({
            event: GoogleTagManagerEvents.formInteractions,
            interaction_type: "submitted",
            interaction_item: "AmbassaadorRegistrationForm"

        });

        setFromSubmissionInProgress(true);
        setButtonDisabled(true);
        const res = await fetch("/api/apply-ambassador-program", {
            method: "POST",
            body: JSON.stringify(e),
        })
            .then((data) => {
                if (data.status === 200) {
                    router.push(AMBASSADOR_REGISTRATION_SUCCESS_PAGE);
                    setTimeout(() => {
                        reset();
                        setButtonDisabled(false);
                    }, 5000);
                    return;
                }
                setShowErrorToast(true);
                setFromSubmissionInProgress(false);
                setButtonDisabled(false);
                setTimeout(() => {
                    setShowErrorToast(false);
                }, 4000);
                Sentry.captureException(data);
                
            })
            .catch((error) => {
                sendGTMEvent({
                    event: GoogleTagManagerEvents.formInteractions,
                    interaction_type: "error occured",
                    interaction_item: "AmbassaadorRegistrationForm"
        
                });
                console.log("in submit catch", error);
             
                Sentry.captureException(error);

                setFromSubmissionInProgress(false);
                setShowErrorToast(true);
                setTimeout(() => {
                    setShowErrorToast(false);
                }, 4000);
            });
    };
    return (
        <div id={FORM_ID} className={SECTION_CONTAINER}>
            {fromSubmissionInProgress && <ProgressLoader />}
            <div className={SECTION_SUB_CONTAINER}>
                <div className={SECTION_HEADING}>
                    <SectionHeading text={data?.title} />
                    <StylingSessionDescription text={data?.description} />
                </div>

                <form onSubmit={handleSubmit(onsubmit)}>
                    <PrismicRichText
                        field={data?.your_details_form_heading}
                        components={{
                            paragraph: ({ children }) => (
                                <p className={FORM_HEADING}>{children}</p>
                            ),
                        }}
                    />
                    <div className={FORMS_CONTAINER}>
                        <div className={ROW_CONTAINER}>
                            <div className={FIELD_CONTAINER}>
                                <InputField
                                    label={data?.first_name_label?.[0]?.text}
                                    placeholder={
                                        data?.first_name_placeholder?.[0]?.text
                                    }
                                    field={FIRST_NAME_FIELD}
                                    register={register}
                                    errors={errors}
                                    type={TEXT_TYPE}
                                />
                            </div>
                            <div className={FIELD_CONTAINER}>
                                <InputField
                                    label={data?.last_name_label?.[0]?.text}
                                    placeholder={
                                        data?.last_name_placeholder?.[0]?.text
                                    }
                                    field={LAST_NAME_FIELD}
                                    register={register}
                                    errors={errors}
                                    type={TEXT_TYPE}
                                />
                            </div>
                        </div>
                        <div className={ROW_CONTAINER}>
                            <div className={FIELD_CONTAINER}>
                                <InputField
                                    label={data?.email_label?.[0]?.text}
                                    placeholder={
                                        data?.email_placeholder?.[0]?.text
                                    }
                                    field={EMAIL_FIELD}
                                    register={register}
                                    errors={errors}
                                    type={EMAIL_TYPE}
                                />
                            </div>
                            <div className={FIELD_CONTAINER}>
                                <DropdownField
                                    label={data?.country_label?.[0]?.text}
                                    placeholder={
                                        data?.country_placeholder?.[0]?.text
                                    }
                                    field={COUNTRY_FIELD}
                                    register={register}
                                    errors={errors}
                                    options={COUNTRY_LIST}
                                    local
                                    locale={router?.locale}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={REASON_FIELD_CONTAINER}>
                                <TextArea
                                    countCharacters
                                    customStyle={" !h-[46px] !pt-2"}
                                    label={
                                        data?.ambassador_reason_label?.[0]?.text
                                    }
                                    placeholder={
                                        data?.ambassador_reason_placeholder?.[0]
                                            ?.text
                                    }
                                    field={REASON_FIELD}
                                    register={register}
                                    errors={errors}
                                    type={TEXT_TYPE}
                                    height={REASON_FIELD_HEIGHT}
                                    maxCharacterLength={FOUR_HUNDRED}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={SOCIAL_CHANNEL_HEADING}>
                        <PrismicRichText
                            field={data?.social_platforms_form_heading}
                            components={{
                                paragraph: ({ children }) => (
                                    <p className={FORM_HEADING}>{children}</p>
                                ),
                            }}
                        />
                    </div>
                    <div className={SOCIAL_FORM_CONTAINER}>
                        {fields?.map(({ id }, index) => {
                            const socialChannelLabel =
                                data?.social_channel_label?.[0]?.text +
                                " " +
                                0 +
                                (index + 1);
                            return (
                                <div key={id} className={SOCIAL_FIELD_ROW}>
                                    <div className={SOCIAL_CHANNEL_FIELD}>
                                        <FieldArrayDropdown
                                            label={socialChannelLabel}
                                            field={CHANNEL_NAME_FIELD(index)}
                                            register={register}
                                            errorFieldName={
                                                errors?.social_channels?.[0]
                                            }
                                            options={socialMediaChannelsList}
                                            locale={router?.locale}
                                            destructureFieldName={
                                                CHANNEL_NAME_TEXT
                                            }
                                        />
                                    </div>
                                    <div className={SOCIAL_CHANNEL_URL_FIELD}>
                                        <FieldArrayInput
                                            label={
                                                data?.username_label?.[0]?.text
                                            }
                                            field={CHANNEL_HANDLE_FIELD(index)}
                                            register={register}
                                            errors={
                                                errors?.social_channels?.[0]
                                            }
                                            type={TEXT_TYPE}
                                            destructuredFieldName={
                                                CHANNEL_HANDLE_TEXT
                                            }
                                        />
                                        {index > 0 || fields?.length >= 2 ? (
                                            <div
                                                onClick={() => remove(index)}
                                                className={BIN_ICON_CLASS}
                                            >
                                                <Image
                                                    height={25}
                                                    width={25}
                                                    src={BinIcon}
                                                    alt={"Bin Icon"}
                                                    loading="lazy"
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <InputErrorMessage
                        errorMessage={errors[SOCIAL_CHANNELS_FIELD]?.message}
                    />
                    {fields?.length < SIX ? (
                        <div className={ADD_MORE_BUTTON_CONTAINER}>
                            <TertiaryButtonSmallSize
                                type={BUTTON_TYPE}
                                onClick={() =>
                                    append({
                                        channel_name: "",
                                        channel_handle: "",
                                    })
                                }
                                className={TERTIARY_BUTTON}
                                text={data?.add_channel_button_text}
                            />
                        </div>
                    ) : null}
                    <div className={CHECKBOX_CONTAINER}>
                        <Checkbox
                            errors={errors}
                            field={CHECK_BOX_FIELD}
                            label={termsAndConditions}
                            type={CHECKBOX_TYPE}
                            register={register}
                            locale={router?.locale}
                        />
                    </div>
                    <div className={PRIMARY_BUTTON_CONTAINER}>
                        <PrimaryButton
                            text={data?.submit_button_text?.[0]?.text}
                            type={SUBMIT_TYPE}
                            width={" w-56 "}
                            disabled={buttonDisabled}
                        />
                    </div>
                </form>
            </div>
            {showErrorToast ? <ErrorToast /> : null}
        </div>
    );
};

export default AmbassadorRegistrationForm;
