"use client";

import React from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useSelector } from "react-redux";
import { updateDescription } from "./constants";
import NewCardText from "../../Atoms/Typography/NewCardText";

const HowItWorksIntlCard = ({
    index,
    heading,
    description,
    description2,
    descriptionBold,
    image,
}) => {
    const { userCountry } = useSelector((state) => state?.country);
    return (
        <div className="flex flex-col max-w-[319px] sm:max-w-[458px] items-center rounded-3xl shadow-2xl overflow-hidden">
            <div className=" relative w-[319px] h-[159px] sm:w-[458px] sm:h-[229px] ">
                <Image
                    src={image}
                    alt={heading}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
            </div>
            <div className="p-6">
                <NewCardText
                    text={heading}
                    className="font-bold text-lg mb-[18px]"
                />
                <NewCardText
                    text={description}
                    className=" text-sm mb-[14px] sm:text-[17px]"
                />
                {description2 && (
                    <NewCardText
                        text={updateDescription(description2, userCountry)}
                        className=" text-sm mb-[14px] sm:text-[17px]"
                    />
                )}
                {descriptionBold && (
                    <NewCardText
                        text={descriptionBold}
                        className=" text-sm font-bold mb-[14px] sm:text-[17px]"
                    />
                )}
            </div>
        </div>
    );
};

export default HowItWorksIntlCard;
