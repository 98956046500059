import React from "react";
import Image from "next/image";
import { playIcon, pauseIcon } from "../../../../public/Theme";

const PlayingIcon = ({ showButtons }) => {
    return (
        <>
            {showButtons?.play ? (
                <Image
                    alt="play-icon"
                    height={50}
                    width={50}
                    src={playIcon}
                    className="w-8 h-8 lg:w-10 lg:h-10"
                />
            ) : (
                <Image
                    alt="pause-icon"
                    height={50}
                    width={50}
                    src={pauseIcon}
                    className="w-8 h-8 lg:w-10 lg:h-10"
                />
            )}
        </>
    );
};

export default PlayingIcon;
