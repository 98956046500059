import * as yup from "yup";
import { ARABIC } from "../../../public/Constants/EnumConstants";

const AMBASSADOR_REGISTRATION_FORM_SCHEMA = yup.object({
    first_name: yup.string().required("Please Enter your First Name"),
    last_name: yup.string().required("Please Enter your Last Name"),
    email: yup
        .string()
        .email("Please Enter a Valid Email")
        .required("Please Enter Your Email"),
    country: yup.string().required("Please Select a Country"),
    reason: yup.string().required("Please Enter a Reason"),
    social_channels: yup
        .array()
        .of(
            yup.object({
                channel_name: yup.string().required("Please Select a Channel"),
                channel_handle: yup
                    .string()
                    .required("Please Enter your Social Channel Name"),
            })
        )
        .compact((v) => !v.channel_name || !v.channel_handle)
        .min(1, "Add at least 1 Social Channel"),
    // .required("Please add your Social Channel"),
    checkbox: yup.boolean().oneOf([true], "Please Check this box"),
});
const AMBASSADOR_REGISTRATION_FORM_SCHEMA_AR = yup.object({
    first_name: yup.string().required("يرجى ادخال الاسم الاول"),
    last_name: yup.string().required("الرجاء إدخال اسم العائلة الخاص بك"),
    email: yup
        .string()
        .email("يرجى إدخال البريد الإلكتروني الصحيح")
        .required("رجاءا أدخل بريدك الإلكتروني"),
    country: yup.string().required("رجاء قم بإختيار دوله"),
    reason: yup.string().required("الرجاء إدخال سبب"),
    social_channels: yup
        .array()
        .of(
            yup.object({
                channel_name: yup.string().required("الرجاء تحديد قناة"),
                channel_handle: yup
                    .string()
                    .required("الرجاء إدخال اسم القناة الاجتماعية الخاصة بك"),
            })
        )
        .compact((v) => !v.channel_name || !v.channel_handle)
        .min(1, "أضف قناة اجتماعية واحدة على الأقل"),
    // .required("الرجاء إضافة القناة الاجتماعية الخاصة بك"),
    checkbox: yup.boolean().oneOf([true], "الرجاء تحديد هذا المربع"),
});
export const getFormSchema = (locale) => {
    return locale === ARABIC
        ? AMBASSADOR_REGISTRATION_FORM_SCHEMA_AR
        : AMBASSADOR_REGISTRATION_FORM_SCHEMA;
};
export const DEFAULT_VALUES = {
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    reason: "",
    social_channels: [{ channel_name: "", channel_handle: "" }],
    checkbox: false,
    // channel_name: "",
    // channel_handle: "",
};
