"use client";

import HowWeGotHereSection from "../../components/Molecules/HowWeGotHereSection";

const HowWeGotHere = ({ slice }) => {
    return (
        <section>
            <HowWeGotHereSection slice={slice} />
            <style jsx>{`
                section {
                    margin: auto auto 6em auto;
                    text-align: center;
                    max-width: 1440px;
                    display: flex;
                    justify-content: center;
                }
                @media screen and (max-width: 500px) {
                    section {
                        margin-bottom: auto auto 5em auto;
                    }
                }
            `}</style>
        </section>
    );
};

export default HowWeGotHere;
