import { PrismicRichText } from "@prismicio/react";
import React from "react";
import Image from "next/image";

import { ICON_CONTAINER, DETAILS_CLASS } from "./constants";

const PerksAndBenefitsAtom = ({ imgUrl, detail, bgColor }) => {
    //Comeback
    return (
        <div>
            <div
                className={ICON_CONTAINER}
                style={{ backgroundColor: bgColor }}
            >
                <Image
                    width={50}
                    height={50}
                    src={imgUrl}
                    className="my-auto"
                    alt={"Perks and benifits Icon"}
                    loading="lazy"
                />
                <PrismicRichText
                    field={detail}
                    components={{
                        paragraph: ({ children }) => (
                            <p className={DETAILS_CLASS}>{children}</p>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default PerksAndBenefitsAtom;
