import Image from "next/image";
import React from "react";
import { RightArrow } from "../../../../public/Theme";

const RoundRightArrows = ({ className, style, onClick }) => {
    return (
        <button
            className={className}
            style={{ ...style, top: "30% !important" }}
            onClick={onClick}
        >
            <Image
                fill
                alt="right arrow rounded"
                src={RightArrow}
                sizes="100vw"
            />
        </button>
    );
};

export default RoundRightArrows;
