import { ARABIC, ENGLISH } from "../../../public/Constants/EnumConstants";
export const BACKGROUND_IMAGE_CONTAINER_CLASS =
    " bg-no-repeat bg-cover xl:bg-contain min-h-[230px] md:h-[420px] lg:h-auto 4xl:h-[500px] bg-center   bg-transparent ";
const STYLING_SESSION_LEFT_ALIGNMENT =
    "px-4 lg:px-20 py-10 mr-0 lg:mr-[20%] xl:mr-[40%] text-center md:text-left";
const STYLING_SESSION_RIGHT_ALIGNMENT =
    "px-4 lg:px-20 py-10 ml-0 lg:ml-[20%] xl:ml-[40%] text-center lg:text-right";
export const getStylingSessionInnerContainerClass = (locale) => {
    return locale === ENGLISH
        ? STYLING_SESSION_LEFT_ALIGNMENT
        : STYLING_SESSION_RIGHT_ALIGNMENT;
};
export const INNER_CONTAINER_ONE =
    "max-w-full md:max-w-[60%]  mx-2 md:mx-auto  md:mb-10";
export const TEXT_CONTAINER =
    "  p-2 md:px-12 md:py-[5%] ml-[30%] lg:ml-[30%] xl:ml-[40%] 2xl:mr-[10%] h-full   ";
export const TEXT_CONTAINER_ARABIC =
    "  p-2  md:px-12 md:py-[5%] ml-[20%] lg:ml-[25%] xl:ml-[38%] 2xl:mr-[10%] h-full   ";

export const TEXT_INNER_CONTAINER_ONE =
    "ml-[50%] md:ml-[60%] mt-2 md:mt-7 2xl:mt-11 md:w-[220px] ";
export const TEXT_INNER_CONTAINER_TWO = "flex mt-7 md:mt-5 ";
export const ABOUT_US_ENDPOINT = "/about-us";
export const getTextContainerClass = (locale) => {
    return locale === ARABIC ? TEXT_CONTAINER_ARABIC : TEXT_CONTAINER;
};

export const WHO_WE_ARE_LEARN_MORE_ID = "who_we_are_learn_more_id";
