import { ENGLISH } from "../../../public/Constants/EnumConstants";

export const ARABIC_ORDER_CLASS_ONE = "lg:col-start-2";
export const ARABIC_ORDER_CLASS_TWO = "lg:row-start-1";

export const SECTION_HEADING_CONTAINER = "py-4 mb-4";

export const HOW_IT_WORKS_ROW = "flex flex-col lg:flex-row w-[90%] mx-auto";
export const HOW_IT_WORKS_SECOND_ROW = " flex justify-center items-center ";
const BUTTON_TEXT = "Start The Style Quiz";
const BUTTON_TEXT_AR = "ابدأ اختبار الأسلوب";
export const getSectionButtonText = (locale) => {
    return locale === ENGLISH ? BUTTON_TEXT : BUTTON_TEXT_AR;
};
