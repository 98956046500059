import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { LIGHT_PARAGRAPH_STYLE } from "./constants";

const ParagraphLight = ({ text, alignment }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <p className={LIGHT_PARAGRAPH_STYLE}>{children}</p>
                ),
                paragraph: ({ children }) => (
                    <p className={LIGHT_PARAGRAPH_STYLE}>{children}</p>
                ),
            }}
        ></PrismicRichText>
    );
};

export default ParagraphLight;
