import { ARABIC } from "../../../public/Constants/EnumConstants";

export const ARABIC_ORDER_CLASS_ONE = "lg:col-start-2";
export const ARABIC_ORDER_CLASS_TWO = "lg:row-start-1";

export const HOW_IT_WORKS_FIRST_ROW =
    "max-w-screen-lg  mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-x-2 md:gap-x-12 gap-y-4 md:gap-y-14 py-5 mt-2 md:mt-10  ";
export const HOW_IT_WORKS_SECOND_ROW =
    " flex justify-center items-center pt-4 md:pt-7 ";
const BUTTON_TEXT = "Start The Style Quiz";
const BUTTON_TEXT_AR = "ابدأ اختبار الأسلوب";
export const getSectionButtonText = (locale) => {
    return locale === ARABIC ? BUTTON_TEXT_AR : BUTTON_TEXT;
};

export const HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT =
    "how-it-works-section-button";
