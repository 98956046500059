export const PAGE_CONTAINER = "flex flex-col justify-items-center my-10 ";
export const SECTION_SUB_CONTAINER = "w-[80%] self-center";
export const PAGE_HEADING =
    "text-Montserrat text-2xl md:text-[40px] font-semibold text-center leading-9 md:leading-[72px]";
export const CHOOSE_GIFT_CARD_HEADING =
    "text-center md:text-left text-Montserrat font-normal text-sm md:text-2xl leading-[16.8px] md:leading-9";
export const FORM_AND_CARD_DESIGN_CONTAINER =
    "flex flex-col lg:flex-row  lg:gap-x-6 mb-[35px] md:mb-[80px]";
export const IMAGE_SELECTOR_CONTAINER = "lg:w-[80%] ";
export const SENDER_DETAILS_FORM_CONTAINER =
    "md:w-[70%] mx-auto mb-[25px] md:mb-[35px]";
export const BUTTON_CONTAINER = "grid mb-[28px] md:mb-[46px]";
export const HEADING_CLASS =
    "text-center md:text-start text-[14px] md:text-[24px] mt-[35px] md:mt-[50px] mb-[12px] md:mb-[20px]";

export const SUBMIT_TYPE = "submit";

export const GiftCardCurrencies = {
    AED: [500, 700, 1000, 1200, 1500, 2000, 5000],
    SAR: [500, 700, 1000, 1200, 1500, 2000, 5000],
    AUD: [100, 250, 500, 750, 1000, 1250, 1500],
    GBP: [100, 250, 500, 750, 1000, 1250, 1500],
    EUR: [100, 250, 500, 750, 1000, 1250, 1500],
    USD: [100, 250, 500, 750, 1000, 1250, 1500],
};

//Form Field

export const RECEIVER_FIRST_NAME_FIELD = "recipient_first_name";
export const RECEIVER_LAST_NAME_FIELD = "recipient_last_name";
export const RECEIVER_EMAIL_FIELD = "recipient_email";
export const MESSAGE_FIELD = "gift_message";

export const SENDER_FULL_NAME_FIELD = "sender_full_name";
export const SENDER_EMAIL_FIELD = "sender_email";
export const DATE_FIELD = "date";
export const TIME_FIELD = "time";