import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { SECTION_HEADING_CLASS } from "./constants";

const SectionHeadingNormal = ({ text }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <h1 className={SECTION_HEADING_CLASS}>{children}</h1>
                ),
                paragraph: ({ children }) => (
                    <h1 className={SECTION_HEADING_CLASS}>{children}</h1>
                ),
            }}
        ></PrismicRichText>
    );
};

export default SectionHeadingNormal;
