export const ARROW_CLASS = "transition-all delay-50 duration-300 ";

export const ARROW_ROTATE = " rotate-180 ";


export const ACCORDION_CONTAINER = "border-b border-gray-200 py-4";

export const QUESTION_CONTAINER = "flex justify-between items-center w-full text-left";

export const QUESTION_STYLE =
    "font-[16px] font-[400] text-[#262626] font-Montserrat lg:text-[24px]";

    export const ANSWER_STYLE =
        "mt-2 font-[13px] font-[300] text- font-Montserrat lg:text-[16px]";