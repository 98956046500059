"use client";

import React from "react";
import dynamic from "next/dynamic";

import {
    CONTAINER_CLASS,
    DETAILS_CONTAINER,
    SIGNATURE_CONATINER,
    SIGNATURE_IMG,
    BREAK_POINT_WIDTH,
} from "./constants";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

const ImageAtom = dynamic(() => import("../../Atoms/ImageAtom"));

const WhoWeAreSectionVersion3 = ({ data }) => {
    const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);

    return (
        <div className={CONTAINER_CLASS}>
            <div>
                <ImageAtom
                    style={"w-full"}
                    image={
                        !isBreakpoint
                            ? data?.banner_image
                            : data?.banner_mobile_image
                    }
                />
            </div>
            <div className={DETAILS_CONTAINER}>
                <TypographyMontserrat
                    text={data?.title?.[0]?.text}
                    align="left"
                    customStyle={"justify mt-5"}
                    size="4xl"
                    fontWeight={"semi_bold"}
                    color={" New_Primary_Default"}
                />
                <TypographyMontserrat
                    color={"Light_Black"}
                    text={data?.description?.[0]?.text}
                    align="left"
                    size="lg"
                    customStyle={"mt-3"}
                />
                <TypographyMontserrat
                    text={data?.secondary_title}
                    align="left"
                    size="lg"
                    color={"Light_Black"}
                />
                <div className={SIGNATURE_CONATINER}>
                    <ImageAtom
                        style={SIGNATURE_IMG}
                        image={data?.signature_image}
                    />
                </div>
            </div>
        </div>
    );
};

export default WhoWeAreSectionVersion3;
