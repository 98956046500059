"use client";
import React from "react";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

const LittleAboutMeImage = ({ slice }) => {
    const breakpoint = useMediaQuery("770");
    const stylistCard = breakpoint
        ? slice?.primary?.stylist_card_mobile?.url
        : slice?.primary?.stylist_card?.url;

    return (
        <img
            src={stylistCard}
            alt={slice?.primary?.stylist_card_mobile?.alt}
            className={" mt-5 "}
        />
    );
};

export default LittleAboutMeImage;
