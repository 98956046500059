"use client";

import React from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import NewTertiaryButton from "../../Atoms/Buttons/NewTertiaryButton";
import { BlackSimpleArrow } from "../../../public/Theme";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import { GoogleTagManagerEvents } from "../../../common";
import {
    CONTENT_CONTAINER,
    CONTENT_SUB_CONTAINER,
    CTA_BUTTON_CLASS,
    HEADING_CLASS,
    IMAGE_CLASS,
    IMAGE_CONTAINER,
    MAIN_WRAPPER_CONTAINER,
} from "./Style";
import { sendGTMEvent } from "@next/third-parties/google";

const JournalCard = ({ cardData }) => {
    const breakpoint = useMediaQuery("767");
    const image = breakpoint ? cardData?.mobile_image : cardData?.image;
    return (
        <div className={MAIN_WRAPPER_CONTAINER}>
            <div className={CONTENT_CONTAINER}>
                <TypographyMontserrat
                    fontWeight={"medium"}
                    size={"base"}
                    text={cardData?.journal_type}
                />
                <div className={CONTENT_SUB_CONTAINER}>
                    <TypographyMontserrat
                        fontWeight={"normal"}
                        size={"40px"}
                        text={cardData?.journal_heading}
                        customStyle={HEADING_CLASS}
                    />
                    <TypographyMontserrat
                        fontWeight={"normal"}
                        size={"xl"}
                        text={cardData?.journal_description}
                    />
                    <NewTertiaryButton
                        href={cardData?.button_link}
                        className={CTA_BUTTON_CLASS}
                        text={cardData?.button_text}
                        image={BlackSimpleArrow}
                        onClick={() => {
                            sendGTMEvent({
                                event: GoogleTagManagerEvents.navigationInteractions,
                                place: "JournalCard",
                                "menu-name": "JournallCard",
                            });
                        }}
                    />
                </div>
            </div>
            <div className={IMAGE_CONTAINER}>
                <img
                    className={IMAGE_CLASS}
                    src={image?.url}
                    alt={image?.alt}
                    loading="lazy"
                />
            </div>
        </div>
    );
};

export default JournalCard;
