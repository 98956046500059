import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Montserrat_BASE_LIGHT } from "./constants";

const MontserratBaseLight = ({ text, simpleText, alignment, customStyle }) => {
    const textComponent = !simpleText ? (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <p className={Montserrat_BASE_LIGHT + customStyle}>
                        {children}
                    </p>
                ),
                paragraph: ({ children }) => (
                    <p className={Montserrat_BASE_LIGHT + customStyle}>
                        {children}
                    </p>
                ),
            }}
        ></PrismicRichText>
    ) : (
        <p className={Montserrat_BASE_LIGHT + customStyle}>{simpleText}</p>
    );
    return textComponent;
};

export default MontserratBaseLight;
