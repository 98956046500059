"use client";
import React from "react";
import ImageCarouselMolecule from "../../components/Molecules/ImageCarouselMolecule";

/**
 * @typedef {import("@prismicio/client").Content.ImageCarouselSlice} ImageCarouselSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ImageCarouselSlice>} ImageCarouselProps
 * @param { ImageCarouselProps }
 */
const ImageCarousel = ({ slice }) => (
    <section>
        <ImageCarouselMolecule data={slice} />
        <style jsx>{`
            section {
            }
        `}</style>
    </section>
);

export default ImageCarousel;
