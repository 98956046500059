export const SECTION_CONTAINER =
    "bg-FORM_BG flex flex-col justify-items-center";
export const SECTION_SUB_CONTAINER = "w-[90%] md:w-auto  self-center";
export const SECTION_HEADING = "mt-[60px] md:mt-[80px]";
export const FORMS_CONTAINER =
    "flex w-auto self-center grid gap-x-0 md:gap-x-8 ";
export const SOCIAL_FORM_CONTAINER =
    "flex w-auto self-center grid gap-x-0 md:gap-x-8 ";
export const FORM_HEADING =
    "text-center font-semibold text-[22px] md:text-2xl ";
export const ROW_CONTAINER =
    "grid grid-cols-1 md:grid-cols-2 gap-x-[16px] md:gap-x-8";
export const SOCIAL_FIELD_ROW = "grid grid-cols-2 gap-x-4";
export const CHECKBOX_CONTAINER = "flex justify-start mt-[20px]";
export const ADD_MORE_BUTTON_CONTAINER = "flex justify-between";
export const TERTIARY_BUTTON = "underline";
export const FIELD_CONTAINER =
    "my-[8px] md:my-[10px] text-[13px] md:text-[16px]";
export const REASON_FIELD_CONTAINER = "mt-[5px] text-[13px] md:text-[16px]";
export const REASON_FIELD_HEIGHT = " h-[60px] md:h-[80px]";
export const SOCIAL_CHANNEL_HEADING =
    "mt-[35px] md:mt-[60px] mb-[20px] md:mb-[25px]";
export const SOCIAL_CHANNEL_FIELD =
    "w-[100%] mb-[12px] md:mb-[20px] text-[13px] md:text-[16px]";
export const SOCIAL_CHANNEL_URL_FIELD =
    "w-[100%] mb-[12px] md:mb-[20px] text-[13px] md:text-[16px]";
export const PRIMARY_BUTTON_CONTAINER =
    "grid mt-[25px] md:mt-[50px] mb-[60px] md:mb[80px]";
export const TERMS_AND_CONDITIONS_CLASS = "text-Primary_Default";
export const BIN_ICON_CLASS = "flex flex-row-reverse mt-[4px] md:mt-[8px]";

export const FORM_ID = "registrationForm";
export const FIRST_NAME_FIELD = "first_name";
export const LAST_NAME_FIELD = "last_name";
export const EMAIL_FIELD = "email";
export const COUNTRY_FIELD = "country";
export const REASON_FIELD = "reason";
export const SOCIAL_CHANNELS_FIELD = "social_channels";
export const CHECK_BOX_FIELD = "checkbox";
export const CHANNEL_NAME_TEXT = "channel_name";
export const CHANNEL_HANDLE_TEXT = "channel_handle";
export const CHANNEL_NAME_FIELD = (index) =>
    "social_channels[" + index + "].channel_name";
export const CHANNEL_HANDLE_FIELD = (index) =>
    "social_channels[" + index + "].channel_handle";

export const CHANNEL_HANDLE_FIELD_ERROR = (index) =>
    "[social_channels][" + index + "].channel_handle";
