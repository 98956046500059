import React from "react";

import { HOW_IT_WORKS_ROW, SECTION_HEADING_CONTAINER } from "./constants";
import HowItWorksCardVersion3 from "../HowItWorksCardVersion3";
import Montserrat40pxSemibold from "../../Atoms/Typography/Montserrat40pxSemibold";

const HowItWorksVersion3 = ({ title, items }) => {
    return (
        <div dir="ltr">
            <div className={SECTION_HEADING_CONTAINER}>
                <Montserrat40pxSemibold text={title} />
            </div>
            <div className={HOW_IT_WORKS_ROW}>
                {items?.map((item, index) => (
                    <HowItWorksCardVersion3
                        key={index}
                        index={index}
                        heading={item?.steptext}
                        description={item?.description}
                        image={item?.stepIcon}
                    />
                ))}
            </div>
        </div>
    );
};

export default HowItWorksVersion3;
