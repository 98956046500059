import React from "react";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

import {
    BACKGROUND_IMAGE_CONTAINER_CLASS,
    OVER_LAY_HEADING,
    BREAK_POINT_WIDTH,
} from "./constants";
import InStudioStylingHeading from "../../Atoms/Typography/InStudioStylingHeading";

const StylingSessionBannerSection = ({ data }) => {
    const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);
    return (
        <div
            className={BACKGROUND_IMAGE_CONTAINER_CLASS}
            style={{
                backgroundImage: isBreakpoint
                    ? `url(${data?.mobileimage?.url})`
                    : `url(${data?.image?.url})`,
            }}
        >
            <div className={OVER_LAY_HEADING}>
                <InStudioStylingHeading text={data?.title?.[0]?.text} />
            </div>
        </div>
    );
};

export default StylingSessionBannerSection;
