export const PAYMENT_PROCESSING_ADDRESS = `${process?.env?.NEW_WEB_URL}/payment-processing`;
export const CARD_NUMBER_LABEL = "Card Number";
export const CARD_HOLDER_LABEL = "Cardholder Name (optional)";
export const EXPIRY_LABEL = "Expiry";
export const CVV_LABEL = "CVV";
export const SAVE_TEXT = "Save";
export const CANCEL_TEXT = "Cancel";
export const CHECKOUT = "checkbox";
export const CHECKOUT_TEXT = "CONFIRM PAYMENT";

export const CARD_HOLDER_FIELD = "cardholder_name";
export const TERMS_AND_CONDITIONS_LINK = "/documents/terms-and-conditions/";

export const CHECKOUT_LABEL = (
    <>
        I agree with the{" "}
        <a
            href={TERMS_AND_CONDITIONS_LINK}
            target="_blank"
            className="underline uppercase !text-sm sm:text-base font-semibold"
        >
            Terms & Conditions
        </a>
    </>
);
