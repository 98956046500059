import Axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_ENDPOINTS } from "../../../public/Constants/endpoints";
import { getSession } from "next-auth/react";

var MERCHAT_ID = process.env.MERCHAT_ID;
var BACKEND_URL_VALIDATE_SESSION = BACKEND_ENDPOINTS?.VALIDATE_SESSION;
var BACKEND_URL_PAY = BACKEND_ENDPOINTS?.APPLE_PAYMENT;

var appleButton = document.querySelector(".apple-pay-button");

console.log(
    "canmake",
    window?.ApplePaySession?.canMakePaymentsWithActiveCard(MERCHAT_ID)
);
//check if Apple pay is available
if (
    window?.ApplePaySession &&
    ApplePaySession?.canMakePaymentsWithActiveCard(MERCHAT_ID)
) {
    //     show the Apple pay button
    var AppleButton = true;
    //     appleButton.style.display = 'block';
}

export default AppleButton;
//Handle the apple Pay button click

function getApplePaymentData(
    currency,
    amount,
    country,
    promoCode,
    discountAmount
) {
    const applePaymentData = {
        countryCode: country,
        currencyCode: currency,
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        lineItems: [
            {
                label: "Styling Fee",
                amount: String(amount),
            },
        ],
        total: {
            label: "total",
            amount: String(
                parseInt(amount) -
                    (discountAmount ? parseInt(discountAmount) : 0)
            ),
        },
    };
    if (promoCode) {
        applePaymentData["lineItems"].push({
            label: `promo (${promoCode})`,
            amount: discountAmount,
        });
    }
    return applePaymentData;
}
// const removerLoader = () => {
//   var loader = document.getElementById('apple-pay-loader')
//   loader.style.display = 'none'
// }

export const applePayButtonClick = (currency, amount, country) => {
    const promoCode = JSON?.parse(localStorage.getItem("promo_code"))||null;

    const applePaymentData = getApplePaymentData(
        currency,
        amount,
        country,
        promoCode?.code,
        promoCode?.discount_amount
    );
    var applePaySession = new ApplePaySession(6, applePaymentData);
    applePaySession?.begin();

    // const history = useHistory()
    //this is the first event that AppapplePayButtonClickle triggers. Here you need to validate the session
    // Apple pay session from your Back-end
    applePaySession.onvalidatemerchant = function (event) {
        try {
            var theValidationURL = event.validationURL;
            validateTheSession(theValidationURL, function (merchantSession) {
                console.log("Apple Pay Merchant Session ", merchantSession);
                applePaySession.completeMerchantValidation(merchantSession);
            });
        } catch (err) {
            // removerLoader()
            console.log("error onvalidatemerchant", err);
        }
    };
    // This triggers after the user has confirmed the transaction with the Touch ID or Face ID
    // This will contain the payment token

    // Commenting oncancel because the cancel button in popup disables when the user authenticates with fingerprint

    applePaySession.oncancel = function (event) {
        // window.location.reload()
        // removerLoader()
    };

    applePaySession.onpaymentauthorized = function (event) {
        var applePaymentToken = event?.payment?.token;
        console.log("after apple pay session");
        pay(
            applePaymentToken,
            (outcome) => {
                if (outcome) {
                    try {
                        applePaySession.completePayment(
                            ApplePaySession.STATUS_SUCCESS
                        );
                        // let queryparam = localStorage.getItem('queryparam')
                        // console.log("hamza querypara,", queryparam)
                        toast("Payment Successful", { type: "success" });
                        localStorage.removeItem("promo_code");
                        localStorage.removeItem("referral_code");
                        if (country === "AE") {
                            window.location.href = `/checkout/payment-success`;
                        } else {
                            window.location.href = `/checkout/sa-payment-success`;
                        }
                        // localStorage.removeItem('queryparam')
                        // window.location.reload();
                    } catch {
                        // window.location.reload();
                        localStorage.removeItem("promo_code");
                        localStorage.removeItem("referral_code");
                        toast("Payment Successful", { type: "success" });
                        if (country === "AE") {
                            window.location.href = `/checkout/payment-success`;
                        } else {
                            window.location.href = `/checkout/sa-payment-success`;
                        }
                    }
                } else {
                    console.log(
                        "// ApplePaySession.STATUS_FAILURE",
                        ApplePaySession.STATUS_FAILURE
                    );
                    applePaySession.completePayment(
                        ApplePaySession.STATUS_FAILURE
                    );

                    // removerLoader()
                }
            },
            promoCode?.code
        );
    };
};
const validateTheSession = async (theValidationURL, callback) => {
    const session = await getSession();
    const headers = {
        Authorization: `JWT ${session?.accessToken}`,
    };
    Axios.post(
        BACKEND_URL_VALIDATE_SESSION,
        { theValidationURL },
        {
            headers: headers,
        }
    )
        .then((response) => {
            console.log("In response", response?.data);

            callback(response?.data);
        })
        .catch((err) => {
            // removerLoader()

            toast("Validation Failed", { type: "error" });

            console.log("in validation error ", err);
            // removerLoader()
        });
};

var pay = async (applePaymentToken, callback, promoCode) => {
    const session = await getSession();
    const referral_code = localStorage.getItem("referral_code");

    Axios.post(
        BACKEND_URL_PAY,
        {
            token: applePaymentToken,
            promo_code: promoCode,
            discount_code: referral_code,
        },
        {
            headers: {
                Authorization: `JWT ${session?.accessToken}`,
            },
        }
    )
        .then((response) => {
            callback(response?.data);
            console.log("in payment processs", response?.data);
        })
        .catch((err) => {
            console.log("in payment error", err);
        });
};
