"use client";
import React from "react";
import OurValuesSection from "../../components/Molecules/OurValuesSection";

/**
 * @typedef {import("@prismicio/client").Content.OurValuesSlice} OurValuesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<OurValuesSlice>} OurValuesProps
 * @param { OurValuesProps }
 */
const OurValues = ({ slice }) => (
    <section>
        <OurValuesSection data={slice} />
        <style jsx>{`
            section {
                margin: 4em auto;
                text-align: center;
            }
        `}</style>
    </section>
);

export default OurValues;
