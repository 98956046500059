"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import dynamic from "next/dynamic";

import {
    INNER_CONTAINER_ONE,
    MAIN_CONTAINER,
    TEXT_INNER_CONTAINER_ONE,
} from "./constants";
import { MOBILE_SCREEN } from "../../../public/Constants/EnumConstants";

const SectionHeading = dynamic(
    () => import("../../Atoms/Typography/SectionHeading")
);
const ImageAtom = dynamic(() => import("../../Atoms/ImageAtom"));

const TimelineSection = ({ data }) => {
    const isBreakpoint = useMediaQuery(MOBILE_SCREEN);

    return (
        <div className={MAIN_CONTAINER}>
            <div className={INNER_CONTAINER_ONE}>
                <SectionHeading text={data?.title} />
            </div>

            <div className={TEXT_INNER_CONTAINER_ONE}>
                <ImageAtom
                    image={
                        isBreakpoint ? data?.mobile_timeline : data?.timeline
                    }
                />
            </div>
        </div>
    );
};

export default TimelineSection;
