"use client";

import React from "react";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import NewCardText from "../../Atoms/Typography/NewCardText";
import {
    CARD_DISCRIPTION_CLASS,
    CARD_HEADING_CLASS,
    CARD_INDEX_CLASS,
    CARD_WRAPPER_CLASS,
    updateDescription,
} from "./constants";

const HowWeWorksCard = ({
    step,
    heading,
    description,
    image,
    index,
    arabic_order_class,
    orientation = "horizontal",
    haveButton = false,
}) => {
    const { userCountry } = useSelector((state) => state?.country);
    return (
        <div className={CARD_WRAPPER_CLASS(orientation)} key={index}>
            <NewCardText text={step} className={CARD_INDEX_CLASS} />
            {heading && (
                <NewCardText
                    text={heading}
                    className={CARD_HEADING_CLASS(haveButton)}
                />
            )}
            <NewCardText
                text={updateDescription(description, userCountry)}
                className={CARD_DISCRIPTION_CLASS}
            />
        </div>
    );
};

export default HowWeWorksCard;
