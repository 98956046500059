import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { SECTION_HEADING_WITH_ALIGNMENT_CLASS } from "./constants";

const SectionHeadingWithAligments = ({ text, alignment }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <h1
                        className={
                            alignment + SECTION_HEADING_WITH_ALIGNMENT_CLASS
                        }
                    >
                        {children}
                    </h1>
                ),
                paragraph: ({ children }) => (
                    <h1
                        className={
                            alignment + SECTION_HEADING_WITH_ALIGNMENT_CLASS
                        }
                    >
                        {children}
                    </h1>
                ),
            }}
        ></PrismicRichText>
    );
};

export default SectionHeadingWithAligments;
