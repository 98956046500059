import React from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { PILL_CLASS_NAME } from "./styles";
import { pillBorder, pillFontWeight } from "./constants";

const FaqCategoryPill = ({
    category,
    selectedCategory,
    setSelectedCategory,
}) => {
    return (
        <div
            className={pillBorder(category, selectedCategory)}
            tabIndex={0}
            onClick={() => setSelectedCategory(category)}
        >
            <TypographyMontserrat
                fontWeight={pillFontWeight(category, selectedCategory)}
                size={"base"}
                text={category}
            />
        </div>
    );
};

export default FaqCategoryPill;
