"use client";
import { DownArrow } from "../../../public/Theme";
import Image from "next/image";
import { useState } from "react";
import {
    ARROW_CLASS,
    ARROW_ROTATE,
    ACCORDION_CONTAINER,
    QUESTION_CONTAINER,
    QUESTION_STYLE,
} from "./constants";

const FaqAccordian = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div key={question} className={ACCORDION_CONTAINER}>
            <button
                className={QUESTION_CONTAINER}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className={QUESTION_STYLE}>{question}</span>
                <Image
                    alt="downarrow"
                    height={20}
                    width={20}
                    className={ARROW_CLASS + (isOpen ? ARROW_ROTATE : null)}
                    src={DownArrow}
                />
            </button>
            {isOpen && (
                <div className="">
                    {answer}
                </div>
            )}
        </div>
    );
};

export default FaqAccordian;
