"use client";

import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import CustomVideoPlayer from "../CustomVideoPlayer";

import {
    BANNER_BUTTON_ID_TEXT,
    HERO_DESKTOP_VIDEO_CLASS,
    HERO_VIDEO_CONTAINER,
    POSITION_RELATIVE,
    HERO_CONTENT_CONTAINER,
    HERO_DESCRIPTION_CONTAINER,
    TEXT_6XL,
    TEXT_5XL,
    WEAR_THAT_TEXT,
    getInnerContainerText,
    BANNER_PLACEHOLDER,
} from "./constants";

import {
    SIGN_UP_ENDPOINT,
    SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { HomeBannerPlaceHolder } from "../../../public/Theme";

const HeroText = dynamic(() => import("../../Atoms/Typography/HeroText"));
const HeroDescription = dynamic(
    () => import("../../Atoms/Typography/HeroDescription")
);
const PrimaryButton = dynamic(
    () => import("../../Atoms/Buttons/PrimaryButton/PrimaryButton")
);

const BannerSectionVariant2 = ({ data }) => {
    const router = useRouter();

    return (
        <div className={POSITION_RELATIVE}>
            <div className={HERO_VIDEO_CONTAINER}>
                <CustomVideoPlayer
                    autoplay
                    className={HERO_DESKTOP_VIDEO_CLASS}
                    muted
                    videoContainerClass={HERO_VIDEO_CONTAINER}
                    videoPlaceHolder={HomeBannerPlaceHolder}
                    videoUrl={data?.banner_video?.url}
                />
            </div>
            <div className={HERO_CONTENT_CONTAINER}>
                <div className={getInnerContainerText(router?.locale)}>
                    <HeroText
                        simpleText={WEAR_THAT_TEXT}
                        className={TEXT_6XL}
                    />
                    <HeroText text={data?.title} className={TEXT_5XL} />
                    <div className={HERO_DESCRIPTION_CONTAINER}>
                        <HeroDescription text={data?.description} />
                    </div>
                    <br />
                    <PrimaryButton
                        text={data?.button_text}
                        href={SIGN_UP_ENDPOINT}
                        section={SURVEY_START_SECTIONS?.HomeBanner}
                        buttonId={BANNER_BUTTON_ID_TEXT}
                    />
                </div>
            </div>
        </div>
    );
};

export default BannerSectionVariant2;
