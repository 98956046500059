export const SECTION_CONTAINER = "self-center mb-[20px] lg:mb-0  h-[100%] ";
export const SECTION_SUB_CONTAINER = "flex flex-col-reverse md:flex-col";
export const IMAGE_OPTIONS_CONTAINER =
    "mt-4 grid grid-cols-2 md:grid-cols-3 grid-rows-3 md:grid-rows-2 gap-x-4  gap-y-4";
export const SMALL_IMAGE_CLASS_CONTAINER =
    "relative border border-Tile_Outlines rounded";
export const SELECTED_SMALL_IMAGE_CLASS_CONTAINER =
    "relative border-[3px] border-Selected_State_Color rounded";
export const SMALL_IMAGE_CLASS = "";
export const DISPLAYED_IMAGE =
    "border-[3px] border-Tile_Outlines rounded mt-[10%] md:mt-0";
export const PIN_ICON_CONTAINER =
    "flex flex-wrap justify-center items-center absolute w-full top-[-14px]";

export const IMAGE_FIELD_NAME = "gift_card_image";
