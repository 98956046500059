"use client";

import React from "react";
import { PROCESSING_GIF_ALT, BUTTON_IMAGE_ALT } from "./constants";
import {
    BUTTON_IMAGE_CLASS,
    PRIMARY_BUTTON_CLASS,
    PRIMARY_BUTTON_DISABLED_CLASS,
} from "./styles";
import { LoadingWhiteSpinner } from "../../../../public/Theme";
import Image from "next/image";
import Link from "next/link";
import { SIGN_UP_ENDPOINT } from "../../../../public/Constants/EnumConstants";
import { sendGTMEvent } from "@next/third-parties/google";

const NewTertiaryButton = ({
    text,
    onClick,
    processing,
    width,
    href,
    className,
    image,
    style,
    type,
    disabled,
    section,
    buttonId,
}) => {
    const buttonClass = disabled
        ? PRIMARY_BUTTON_DISABLED_CLASS
        : PRIMARY_BUTTON_CLASS;
    return href ? (
        <Link
            href={href}
            id={buttonId}
            data-cy={buttonId}
            passHref
            prefetch
            className={width + PRIMARY_BUTTON_CLASS + className}
            height="25px"
            style={style}
            onClick={() => {
                if (href?.includes(SIGN_UP_ENDPOINT) && section) {
                    sendGTMEvent({
                        event: "survey_start_click",
                        section: section,
                        button_text: text,
                        content_id: "StartSurveyClick",
                    });
                }
                if (onClick) {
                    onClick();
                }
            }}
            name={text}
        >
            {processing ? (
                <Image
                    src={LoadingWhiteSpinner}
                    width={31}
                    height={30}
                    alt={PROCESSING_GIF_ALT}
                />
            ) : (
                text
            )}
            {image && (
                <Image
                    src={image}
                    width={31}
                    height={30}
                    className={BUTTON_IMAGE_CLASS}
                    alt={BUTTON_IMAGE_ALT}
                />
            )}
        </Link>
    ) : (
        <button
            type={type}
            className={width + buttonClass + className}
            style={style}
            onClick={onClick}
            disabled={disabled}
            id={buttonId}
            data-cy={buttonId}
        >
            {processing ? (
                <Image
                    src={LoadingWhiteSpinner}
                    width={31}
                    height={30}
                    alt={PROCESSING_GIF_ALT}
                />
            ) : (
                text
            )}
            {image && (
                <Image
                    src={image}
                    width={31}
                    height={30}
                    className={BUTTON_IMAGE_CLASS}
                    alt={BUTTON_IMAGE_ALT}
                />
            )}
        </button>
    );
};

export default NewTertiaryButton;
