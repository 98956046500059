"use client";

import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { TERTIARY_BUTTON_CLASS } from "./constants";
import classNames from "classnames";
import Link from "next/link";

const TertiaryButtonSmallSize = ({
    text,
    className,
    onClick,
    href,
    style,
    type,
}) => {
    const textClass = classNames(TERTIARY_BUTTON_CLASS, className);
    return href ? (
        <Link
            href={href}
            passHref
            className={TERTIARY_BUTTON_CLASS + className}
            style={style}
            onClick={onClick}
            prefetch
        >
            <PrismicRichText
                field={text}
                components={{
                    heading1: ({ children }) => (
                        <p className={textClass}>{children}</p>
                    ),
                    paragraph: ({ children }) => (
                        <p className={textClass}>{children}</p>
                    ),
                }}
            ></PrismicRichText>
        </Link>
    ) : (
        <button type={type} onClick={onClick}>
            <PrismicRichText
                field={text}
                components={{
                    heading1: ({ children }) => (
                        <p className={textClass}>{children}</p>
                    ),
                    paragraph: ({ children }) => (
                        <p className={textClass}>{children}</p>
                    ),
                }}
            ></PrismicRichText>
        </button>
    );
};

export default TertiaryButtonSmallSize;
