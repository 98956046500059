import Link from "next/link";
import ImageAtom from "../../Atoms/ImageAtom";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import {
    GRID,
    DETAIL_CONTAINER,
    IMAGE_CONATINER,
    LINK_STYLE,
    DESCRIPTION,
    TITLE,
    HEADING,
    BREAK_POINT_WIDTH,
    IMAGE_STYLES,
} from "./contants";

const AboutUsBannerSectionVersion1 = ({ data }) => {
    const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);

    return (
        <div class={GRID}>
            <div class={DETAIL_CONTAINER}>
                <TypographyMontserrat
                    align="left"
                    text={data?.primary?.title}
                    customStyle={HEADING}
                />

                <div>
                    <TypographyMontserrat
                        align={"left"}
                        text={data?.primary?.section_heading}
                        customStyle={TITLE}
                    />
                    <TypographyMontserrat
                        align={"left"}
                        text={data?.primary?.sub_heading}
                        customStyle={TITLE}
                    />
                </div>
                <TypographyMontserrat
                    align={"left"}
                    text={data?.primary?.description}
                    customStyle={DESCRIPTION}
                />
                <Link href={"/dashboard"} className={LINK_STYLE}>
                    {data?.primary?.link_text}
                </Link>
            </div>

            <div class={IMAGE_CONATINER}>
                <ImageAtom
                    style={IMAGE_STYLES}
                    image={
                        !isBreakpoint
                            ? data?.primary?.banner_image
                            : data?.primary?.mobile_banner_image
                    }
                />
            </div>
        </div>
    );
};
export default AboutUsBannerSectionVersion1;
