export const MODAL_CONTAINER =
    "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ";
export const MODAL_SUB_CONTAINER =
    "w-[343px] md:w-[520px] h-auto absolute bottom-[30px] md:bottom-[40px]  max-w-3xl";
export const MODAL_CONTENT_CONTAINER =
    "border-2 border-Error_Default shadow-[0_0_10px_0_rgba(0,0,0,0.502)] rounded-lg shadow-lg relative flex flex-col h-[62px] md:h-[61px] w-[335px] md:w-[600px] bg-white outline-none focus:outline-none";
export const MODAL_BODY =
    "flex flex-row items-center justify-start relative px-[20px] flex-auto";
export const ERROR_ICON = "mr-[16px]";
export const MODAL_BODY_TEXT_CLASS =
    "text-Montserrat font-medium text-sm text-center";
export const ERROR_TEXT = "Sorry something went wrong";
