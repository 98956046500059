"use client";
import React from "react";
import "./StylistProfileVideo.css";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import NewCustomVideoPlayer from "../../Molecules/NewCustomVideoPlayer";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

const StylistProfileVideo = ({ slice }) => {
    const breakpoint = useMediaQuery("500");
    const imageSrc = breakpoint
        ? slice?.primary?.stylist_video_mobile?.url
        : slice?.primary?.stylist_video?.url;
    return (
        <div className=" w-full  bg-Card_Background px-[17px] lg:px-[120px] ">
            <div className=" my-[30px]">
                <TypographyMontserrat
                    children={slice?.primary?.title}
                    fontWeight={"semibold"}
                    customStyle={
                        " !text-2xl sm:!text-[36px] mb-[30px] uppercase"
                    }
                />
            </div>
            <div className=" mb-[30px] lg:mb-[60px]">
                {imageSrc ? (
                    <NewCustomVideoPlayer autoplay muted videoUrl={imageSrc} />
                ) : (
                    <div className="h-[500px] sm:h-auto">
                        <img
                            src={slice?.primary.stylist_image?.url}
                            className="h-full object-cover object-center"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StylistProfileVideo;
