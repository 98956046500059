import * as yup from "yup";
import { ARABIC } from "../../../public/Constants/EnumConstants";

const STYLING_SESSION_FORM_SCHEMA_EN = yup.object({
    name: yup.string().required("Please Enter your Name"),
    email: yup
        .string()
        .email("Please Enter a Valid Email")
        .required("Please Enter Your Email"),
    date: yup
        .date()
        .typeError("Please Select a Date")
        .required("Please Select a Date"),
    time: yup
        .string()
        .typeError("Please Select a Time")
        .required("Please Select a Time"),
});
const STYLING_SESSION_FORM_SCHEMA_AR = yup.object({
    name: yup.string().required("من فضلك أدخل إسمك"),
    email: yup
        .string()
        .email("يرجى إدخال البريد الإلكتروني الصحيح")
        .required("رجاءا أدخل بريدك الإلكتروني"),
    date: yup
        .date()
        .typeError("يرجى تحديد التاريخ")
        .required("يرجى تحديد التاريخ"),
    time: yup
        .string()
        .typeError("الرجاء تحديد الوقت")
        .required("الرجاء تحديد الوقت"),
});

export const getFormSchema = (locale) => {
    return locale === ARABIC
        ? STYLING_SESSION_FORM_SCHEMA_AR
        : STYLING_SESSION_FORM_SCHEMA_EN;
};
export const DEFAULT_VALUES = {
    name: "",
    email: "",
    date: null,
    time: "",
};
