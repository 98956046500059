export const BACKEND_ENDPOINTS = {
    ECOM_PRODUCTS: "/events/products?page=1",
    PRODUCT: "/events/product/",
    CALCULATED_DATE_RETURN: `${process?.env?.BACKEND_URL}box/calculated-date-return`,
    CREATE_BOX: `${process?.env?.BACKEND_URL}box/create-box`,
    VALIDATE_SESSION: `${process?.env?.BACKEND_URL}payment/validateSession/`,
    APPLE_PAYMENT: `${process?.env?.BACKEND_URL}payment/ApplePayPayment/`,
    GIFT_APPLE_PAYMENT: `${process?.env?.BACKEND_URL}payment/giftApplePayment/`,
    GIFT_CARD_VALIDATE_SESSION: `${process?.env?.BACKEND_URL}payment/validateApplePayGiftCardSession/`,
};
export const FRONTEND_ENDPOINTS = {
    HOME: "/",
    TAMARA: process?.env?.ECOM_SHOP_LINK + "tamara",
    PASSWORD_RESET_SUCCESS: "/login?toast=true",
    SURVEY_ENDPOINT: "/survey",
    CHECKOUT_ENDPOINT: "/checkout",
    EMAIL_VERIFICATION_ENDPOINT: "/survey-complete",
    ORDER_A_BOX_ENDPOINT: "/dashboard/orderABox",
    SA_PAYMENT_SUCCESS: "/checkout/sa-payment-success",
};
