export const VIP_TREATMENT_HEADING =
    "text-lg md:text-[40px] font-semibold text-center font-Montserrat mb-[8px] md:mb-[12px]";
export const BOOK_A_SESSION_TEXT =
    "text-sm md:text-lg font-light text-center font-Montserrat";
export const VIP_TREATMENT_DIV = "mt-[30px] w-[90%] mx-auto ";
export const FORMS_CONTAINER =
    "flex w-auto self-center justify-center mx-auto px-1 sm:px-[20%]  mt-[16px] md:mt-[30px] grid grid-cols-1  md:grid-cols-2  gap-x-0 md:gap-x-8 ";
export const FORM_CONTAINER =
    "px-4 mb-[20px] md:mb-[0px] flex flex-col self-center justify-center";
export const BUTTON_CONTAINER =
    "flex justify-center mt-[32px] md:mt-[98px] mb-[30px]";
export const INPUT_FIELD_CONTAINER = "h-[90px]	";

export const FORM_HEADING = "w-max mb-[20px] md:mb-[40px] font-medium text-lg";

export const BUTTON_TEXT = "Confirm Booking";

export const NAME_PLACEHOLDER = "Your Name";
export const EMAIL_PLACEHOLDER = "Your Email";

export const TEXT_TYPE = "text";
export const EMAIL_TYPE = "email";
export const DATE_TYPE = "date";
export const TIME_TYPE = "time";
export const SUBMIT_TYPE = "submit";

export const NAME_LABEL = "Name";
export const EMAIL_LABEL = "Email";
export const DATE_LABEL = "Select Date";
export const TIME_LABEL = "Select Time";

export const NAME_FIELD = "name";
export const EMAIL_FIELD = "email";
export const DATE_FIELD = "date";
export const TIME_FIELD = "time";
