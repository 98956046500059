import React from "react";
import { CheckboxTick } from "../../../../public/IconComponents/CheckboxTick";

import {
    INPUT_CONTAINER,
    LABEL,
    CHECK_BOX_CONTAINER,
    CHECK_BOX_INPUT,
    CUSTOM_BOX_CONTAINER,
} from "./constants";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const Checkbox = ({
    errors,
    label,
    type = "checkbox",
    field,
    register,
    locale,
}) => {
    return (
        <div>
            <div className={INPUT_CONTAINER}>
                <div className={CHECK_BOX_CONTAINER}>
                    <input
                        type={type}
                        className={CHECK_BOX_INPUT}
                        {...register(field)}
                    />
                    <div className={CUSTOM_BOX_CONTAINER}>
                        <CheckboxTick />
                    </div>
                    {typeof label === "function" ? (
                        label(LABEL(locale))
                    ) : (
                        <p className={LABEL(locale)}>{label}</p>
                    )}
                </div>
                <div>
                    <ErrorTextWithIcon text={errors?.[field]?.message} />
                </div>
            </div>
        </div>
    );
};

export default Checkbox;
