import React from "react";

import { CONTAINER_CLASS } from "./constants";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import ParagraphRegular from "../../Atoms/Typography/ParagraphRegular";
import SectionHeadingNormal from "../../Atoms/Typography/SectionHeadingNormal";
import {
    SIGN_UP_ENDPOINT,
    VERTICAL_PADDING_TWO,
} from "../../../public/Constants/EnumConstants";
import Montserrat40pxSemibold from "../../Atoms/Typography/Montserrat40pxSemibold";

const HeadingWithDescription = ({ data }) => {
    return (
        <div className={CONTAINER_CLASS} dir="ltr">
            {data?.uppercase_heading ? (
                <Montserrat40pxSemibold text={data?.title} />
            ) : (
                <SectionHeadingNormal text={data?.title} />
            )}
            <div className={VERTICAL_PADDING_TWO}>
                <ParagraphRegular text={data?.description} />
            </div>

            <PrimaryButton text={data?.button_text} href={SIGN_UP_ENDPOINT} />
        </div>
    );
};

export default HeadingWithDescription;
