import CenterAlignedRoundRightArrow from "../../Atoms/CarouselElements/CenterAlignedRoundRightArrow";
import CenterAlignedRoundLeftArrow from "../../Atoms/CarouselElements/CenterAlignedRoundLeftArrow";

export const HERO_CONTENT_CONTAINER =
    "z-50 absolute  min-h-[300px] top-0 left-0 h-[100%] w-[100%] text-center flex justify-center !items-center";

export const HERO_CONTENT_INNER_CONTAINER =
    "mb-8 w-[90%] grid grid-cols-1 text-center bg-[#D3D3D3] bg-opacity-80 justify-center !items-center align-middle p-5 md:static mt-[40%] sm:mt-[50%] md:mt-[10%]";
export const RELATIVE_CLASS = "relative h-[60vh] sm:h-fit overflow-y-hidden";
export const CAROUSEL_IMAGE_CONTAINER = "!flex !justify-center";
export const LEFT_TEXT = "!text-left";
export const DATE_CUSTOM_CLASS = "!text-left !font-normal";
export const HEADING_CONTAINER = "max-w-[80%] mx-auto";
export const DESCRIPTION_CONTAINER = "lg:max-w-[55%] mx-auto";
export const BUTTON_CONTAINER = "mt-4";

export const settings = {
    dots: false,
    slidesToShow: 1,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    draggable: true,
    swipe: true,
    infinite: true,
    nextArrow: <CenterAlignedRoundRightArrow />,
    prevArrow: <CenterAlignedRoundLeftArrow />,
    arrows: true,
};
