import React from "react";
import { PrismicNextImage } from "@prismicio/next";
import { EAGER, LAZY } from "../../../public/Constants/EnumConstants";

const ImageAtom = ({ image, style, styles, eagerLoading }) => {
    return (
        <PrismicNextImage
            field={image}
            className={style}
            style={{ ...styles }}
            loading={eagerLoading ? EAGER : LAZY}
            alt=""
        />
    );
};

export default ImageAtom;
