export const GRID = "grid grid-cols-1 lg:grid-cols-2 my-44";

export const DETAIL_CONTAINER =
    "flex flex-col justify-start  lg:justify-center p-5 bg-[#F4F2F3] lg:p-12 order-2 lg:order-1";

export const IMAGE_CONATINER = "w-full h-full order-1 lg:order-2";

export const LINK_STYLE =
    "uppercase underline text-left font-Montserrat font-[700] text-[16px] mt-5 lg:mt-20";

export const DESCRIPTION =
    "text-[#262626] text-[16px] leading-[19.2px] lg:text-[20px] lg:leading-[24px] mt-5";

export const TITLE =
    "text-[#262626] text-[24px] leading-[28.8px] lg:text-[41px] lg:leading-[49.2px]";
export const HEADING = "text-[14px] uppercase lg:text-[16px] mb-10  lg:mb-20";

export const BREAK_POINT_WIDTH = 540;

export const IMAGE_STYLES = "object-cover h-full w-full";
