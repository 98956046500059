"use client";
import React from "react";
import NewPrimaryButton from "../NewPrimaryButton/NewPrimaryButton";
import { useSelector } from "react-redux";
import { FRONTEND_ENDPOINTS } from "../../../../public/Constants/endpoints";
import { SAUDI_ISO } from "../../../../public/Constants/EnumConstants";

const NewPrimaryButtonWrapper = ({
    text,
    onClick,
    processing,
    width,
    href,
    className,
    style,
    type,
    disabled,
    section,
    buttonId,
    image,
    place,
    event,
    menuName,
}) => {
    const { userData } = useSelector((state) => state?.user);
    const getURL = (userData, href) => {
        if (userData) {
            const {
                submitted_survey,
                email_verified,
                payment_status,
                setting,
            } = userData;

            if (!submitted_survey) {
                return FRONTEND_ENDPOINTS?.SURVEY_ENDPOINT;
            }

            if (!email_verified) {
                return FRONTEND_ENDPOINTS?.EMAIL_VERIFICATION_ENDPOINT;
            }

            switch (payment_status) {
                case "un_paid":
                    return FRONTEND_ENDPOINTS?.CHECKOUT_ENDPOINT;
                case "paid":
                    return setting?.addresses?.[0]?.country_code === SAUDI_ISO
                        ? FRONTEND_ENDPOINTS?.SA_PAYMENT_SUCCESS
                        : FRONTEND_ENDPOINTS?.ORDER_A_BOX_ENDPOINT;
                default:
                    return href;
            }
        }
        return href;
    };
    return (
        <NewPrimaryButton
            text={text}
            onClick={onClick}
            processing={processing}
            width={width}
            href={getURL(userData, href)}
            className={className}
            style={style}
            type={type}
            disabled={disabled}
            section={section}
            buttonId={buttonId}
            image={image}
            place={place}
            googleEventName={event}
            menuName={menuName}
        />
    );
};

export default NewPrimaryButtonWrapper;
