import React from "react";
import { useRouter } from "next/navigation";
import dynamic from "next/dynamic";

import {
    ARABIC_ORDER_CLASS_ONE,
    ARABIC_ORDER_CLASS_TWO,
    getSectionButtonText,
    HOW_IT_WORKS_FIRST_ROW,
    HOW_IT_WORKS_SECOND_ROW,
    HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT,
} from "./constants";
import {
    ENGLISH,
    SIGN_UP_ENDPOINT,
    SURVEY_START_SECTIONS,
    ZERO,
} from "../../../public/Constants/EnumConstants";
import { getAlignment, splitSliceIntoTwoParts } from "../../utils/utils";

const HowItWorksCard = dynamic(() => import("../../Molecules/HowItWorksCard"));
const PrimaryButton = dynamic(
    () => import("../../Atoms/Buttons/PrimaryButton")
);

const HowItWorksSection = ({ slice }) => {
    const router = useRouter();
    // const sliceCopy = { ...slice };
    // const { first_slice, second_slice } = splitSliceIntoTwoParts(sliceCopy);
    return (
        <>
            <div className={HOW_IT_WORKS_FIRST_ROW}>
                {slice?.items?.map((item, index) => (
                    <HowItWorksCard
                        item={item}
                        key={index}
                        index={index}
                        step={item?.stepnumber}
                        heading={item?.steptext}
                        description={item?.description}
                        image={item?.stepimage}
                        imageAlt={item?.stepimage?.alt}
                        alignment={getAlignment(router?.locale)}
                    />
                ))}
            </div>
            <div className={HOW_IT_WORKS_SECOND_ROW}>
                <PrimaryButton
                    text={getSectionButtonText(router?.locale)}
                    href={SIGN_UP_ENDPOINT}
                    section={SURVEY_START_SECTIONS.HowItWorks}
                    buttonId={HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT}
                />
            </div>
        </>
    );
};

export default HowItWorksSection;
