"use client";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import FaqAccordion from "../../Atoms/FaqAccordion";
import { GIFT_CARD_FAQ_CONTAINER } from "./constants";

const GiftCardFaqSlice = ({ slice }) => {
    return (
        <div className={GIFT_CARD_FAQ_CONTAINER}>
            <TypographyMontserrat
                fontWeight="bold"
                align={"center"}
                size={"3xl"}
                customStyle={"uppercase mb-6"}
                text={slice?.primary?.title}
            />

            {slice?.primary?.items?.map((item, key) => (
                <FaqAccordion
                    key={key}
                    question={item?.question}
                    answer={item?.answer}
                />
            ))}
        </div>
    );
};

export default GiftCardFaqSlice;
