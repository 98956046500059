import {
    HOW_IT_WORKS_FIRST_ROW,
    HOW_WE_WORK_MAIN_CONTAINER,
    HOW_WE_WORK_STYLE_CLASS,
    HOW_WE_WORK_TITLE,
    HOW_WE_WORK_WRAPPER_CONTAINER,
} from "./constants";
import { PrismicRichText } from "@prismicio/react";
import HowWeWorksCard from "../../Molecules/HowWeWorksCard";

const HowWeGotHereSection = ({ slice }) => {
    return (
        <div className={HOW_WE_WORK_WRAPPER_CONTAINER}>
            <div className={HOW_WE_WORK_MAIN_CONTAINER}>
                <div className={HOW_WE_WORK_STYLE_CLASS}>
                    <PrismicRichText
                        field={slice?.primary?.title}
                        components={{
                            heading1: ({ children }) => (
                                <p className={HOW_WE_WORK_TITLE}>{children}</p>
                            ),
                        }}
                    ></PrismicRichText>
                </div>
                <div className={HOW_IT_WORKS_FIRST_ROW}>
                    {slice?.primary?.items?.map((item, index) => {
                        return (
                            <HowWeWorksCard
                                key={index}
                                index={index}
                                step={item?.title}
                                description={item?.description}
                                image={item?.image}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
export default HowWeGotHereSection;
