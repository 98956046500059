import React from "react";
import { ExpiryDate } from "frames-react";
import { INPUT_FIELD_CLASS } from "../../../../public/Constants/EnumConstants";
import { FIELD_CONTAINER } from "./constants";
import { InputLabelLight } from "../../Typography/InputLabel";

const CreditCardExpiryField = ({ label }) => {
    return (
        <div className={FIELD_CONTAINER}>
            <InputLabelLight label={label} />
            <ExpiryDate className={INPUT_FIELD_CLASS} />
        </div>
    );
};

export default CreditCardExpiryField;
