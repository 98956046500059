"use client";
import React from "react";
import StylingSessionFormSection from "../../components/Molecules/StylingSessionFormSection";

const StylingSessionForm = ({ slice }) => (
    <section>
        <StylingSessionFormSection data={slice?.primary} items={slice?.items} />
        <style jsx>{`
            section {
                margin: auto;
            }
            @media screen and (max-width: 500px) {
                section {
                    margin: 1em auto;
                }
            }
        `}</style>
    </section>
);

export default StylingSessionForm;
