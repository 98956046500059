"use client";

import ImageAtom from "../../Atoms/ImageAtom";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";
import { TertiaryRightArrow } from "../../../public/Theme";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import {
    BREAK_POINT_WIDTH,
    BANNER_STYLES,
    CONTENT_STYLES,
    BANNER_CONTAINER,
} from "./contants";

const GiftCardBannerSection = ({ data }) => {
    const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);

    return (
        <div class={BANNER_CONTAINER}>
            <ImageAtom
                style={BANNER_STYLES}
                image={
                    !isBreakpoint
                        ? data?.primary?.banner_image
                        : data?.primary?.banner_mobile_image
                }
            />

            <div class={CONTENT_STYLES}>
                <TypographyMontserrat
                    text={data?.primary?.heading}
                    color={"New_Primary_Default"}
                    size={"6xl"}
                    fontWeight={"semibold"}
                    customStyle={"lg:w-[923px] lg:leading-[72px]"}
                />

                <div className="max-w-3xl">
                    <TypographyMontserrat
                        text={data?.primary?.subheading}
                        size={"2xl"}
                        fontWeight={"normal-bold"}
                        color={"New_Primary_Default"}
                        customStyle={"mt-5 capitalize"}
                    />
                </div>
            </div>
        </div>
    );
};

export default GiftCardBannerSection;
