import React from "react";
import Slider from "react-slick";
import { BLOGS_ADDRESS } from "../../../public/Constants/EnumConstants";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";
import MonsterratSmallMedium from "../../Atoms/Typography/MonsterratSmallMedium/MonsterratSmallMedium";
import MontserratBaseLight from "../../Atoms/Typography/MontserratBaseLight";
import Playfair2xlMedium from "../../Atoms/Typography/Playfair2xlMedium";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import {
    BUTTON_CONTAINER,
    CAROUSEL_IMAGE_CONTAINER,
    DATE_CUSTOM_CLASS,
    DESCRIPTION_CONTAINER,
    HEADING_CONTAINER,
    HERO_CONTENT_CONTAINER,
    HERO_CONTENT_INNER_CONTAINER,
    LEFT_TEXT,
    RELATIVE_CLASS,
    settings,
} from "./constants";

const ImageCarouselMolecule = ({ data }) => {
    const breakPoint = useMediaQuery(768);
    const { items } = data;
    return (
        <div className={RELATIVE_CLASS}>
            <div>
                <Slider {...settings}>
                    {items?.map((item, index) => {
                        return (
                            <div className={RELATIVE_CLASS} key={index}>
                                <div className={CAROUSEL_IMAGE_CONTAINER}>
                                    <ImageAtom
                                        image={
                                            breakPoint
                                                ? item?.mobile_image
                                                : item?.image
                                        }
                                    />
                                </div>
                                <div
                                    tabIndex={5}
                                    className={HERO_CONTENT_CONTAINER}
                                >
                                    <div
                                        className={HERO_CONTENT_INNER_CONTAINER}
                                    >
                                        <MonsterratSmallMedium
                                            text={item?.genre}
                                            customStyle={LEFT_TEXT}
                                        />
                                        <MonsterratSmallMedium
                                            text={item?.date}
                                            customStyle={DATE_CUSTOM_CLASS}
                                        />
                                        <div className={HEADING_CONTAINER}>
                                            <Playfair2xlMedium
                                                text={item?.heading}
                                            />
                                        </div>
                                        <div className={DESCRIPTION_CONTAINER}>
                                            <MontserratBaseLight
                                                text={item?.description}
                                            />
                                        </div>
                                        <div className={BUTTON_CONTAINER}>
                                            <PrimaryButton
                                                href={
                                                    BLOGS_ADDRESS +
                                                    item?.article_link
                                                }
                                                text={item?.button_text}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default ImageCarouselMolecule;
