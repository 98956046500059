import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { SUB_HEADING_STYLE } from "./constants";

const SubHeading = ({ text, alignment }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <p className={SUB_HEADING_STYLE}>{children}</p>
                ),
                paragraph: ({ children }) => (
                    <p className={SUB_HEADING_STYLE}>{children}</p>
                ),
            }}
        ></PrismicRichText>
    );
};

export default SubHeading;
