"use client";
import { PrismicRichText } from "@prismicio/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    PAGE_CONTAINER,
    CHOOSE_GIFT_CARD_HEADING,
    FORM_AND_CARD_DESIGN_CONTAINER,
    PAGE_HEADING,
    SUBMIT_TYPE,
    SECTION_SUB_CONTAINER,
    SENDER_DETAILS_FORM_CONTAINER,
    BUTTON_CONTAINER,
    HEADING_CLASS,
    IMAGE_SELECTOR_CONTAINER,
    GiftCardCurrencies,
    SENDER_FULL_NAME_FIELD,
    SENDER_EMAIL_FIELD,
    DATE_FIELD,
    TIME_FIELD,
    RECEIVER_FIRST_NAME_FIELD,
    RECEIVER_LAST_NAME_FIELD,
    RECEIVER_EMAIL_FIELD,
    MESSAGE_FIELD,
} from "./constants";
import GiftCardDesignSection from "../GiftCardDesignSection";
import GiftRecipientDetailsForm from "../GiftRecipientDetailsForm";
import {
    DEFAULT_VALUES,
    getFormSchema,
    GIFT_CARD_FORM_SCHEMA_EN,
} from "./FormSchema";
import { formatDate } from "../../utils/utils";
import GiftSenderDetailsForm from "../GiftSenderDetailsForm";
import {
    GIFT_CARD_BACKEND_ENDPOINT,
    GIFT_CARD_ERROR_PAGE,
    GIFT_CARD_SUCCESS_PAGE,
} from "../../../public/Constants/EnumConstants";
import { useRouter } from "next/navigation";
import { sendGTMEvent } from "@next/third-parties/google";
import { GoogleTagManagerEvents } from "../../../common";
import { getCountryDetails } from "../../../public/Constants/CountriesList";
import * as Sentry from "@sentry/nextjs";

const GiftCardFormSection = ({ data, items }) => {
    const { locale } = useRouter();
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const {
        control,
        handleSubmit,
        register,
        formState: { errors, isValid },
        reset,
        getValues,
        setValue,
        watch,
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(getFormSchema(locale)),
    });

    const getCompleteData = ({ token = "" } = {}) => {
        const formValues = getValues();
        const {
            gift_card_image,
            recipient_first_name,
            recipient_last_name,
            sender_full_name,
            sender_email,
            recipient_email,
            gift_message,
            date,
            time,
            gift_card_amount,
        } = formValues;
        const SelectedCurrencyIndex = GiftCardCurrencies?.[
            getCountryDetails()?.currency
        ]?.indexOf(Number(gift_card_amount));
        const completeData = {
            to: recipient_first_name + recipient_last_name,
            from: sender_full_name,
            from_email: sender_email,
            to_email: recipient_email,
            message: gift_message,
            date: formatDate(date),
            amount: GiftCardCurrencies?.AED[SelectedCurrencyIndex],
            converted_amount: gift_card_amount,
            currency_code: getCountryDetails()?.currency,
            token: token ? { token: token } : "",
            time: time,
            giftcard_design_image: gift_card_image,
            discount_code: "",
            success_url: `${process.env.NEW_WEB_URL}${GIFT_CARD_SUCCESS_PAGE}`,
            failure_url: `${process.env.NEW_WEB_URL}${GIFT_CARD_ERROR_PAGE}`,
        };
        return completeData;
    };
    const GiftCardCallAPI = (token) => {
        sendGTMEvent({
            event: GoogleTagManagerEvents.formInteractions,
            interaction_type: "submitted",
            interaction_item: "GiftCardForm",
        });

        try {
            const completeData = getCompleteData(token);
            const url = `${process.env.BACKEND_URL}${GIFT_CARD_BACKEND_ENDPOINT}`;

            const response = fetch(url, {
                body: JSON.stringify(completeData),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) =>
                    response.json().then((data) => ({
                        status: response.status,
                        data: data,
                    }))
                )
                .then((json) => {
                    if (json.status != 200 && json.status != 201) {
                        sendGTMEvent({
                            event: GoogleTagManagerEvents.formInteractions,
                            interaction_item: "GiftCardPayment",
                            interaction_type: "paymentfailed",
                        });

                        const error = new Error("Gift Card Payment Failed");
                        error.code = json.status;
                        throw error;
                    } else if (json.data.redirect_link.includes("success")) {
                        Sentry.captureMessage(
                            `gift card success ${JSON.stringify({
                                data: {
                                    user: completeData.from,
                                    amount: completeData.amount,
                                },
                            })}`
                        );
                    }
                    console.log(json.data);
                    window.location.href = json.data.redirect_link;
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    window.location.href = `${process.env.NEW_WEB_URL}${GIFT_CARD_ERROR_PAGE}`;
                });
        } catch (error) {
            sendGTMEvent({
                event: GoogleTagManagerEvents.formInteractions,
                interaction_type: "erroroccured",
                interaction_item: "GiftCardForm",
            });

            Sentry.captureException(error);
            console.log("error", error);
            // push to error page
        }
    };
    const onsubmit = (e) => console.log(e);

    useEffect(() => {
        const requiredFields = [
            SENDER_FULL_NAME_FIELD,
            SENDER_EMAIL_FIELD,
            DATE_FIELD,
            TIME_FIELD,
            RECEIVER_FIRST_NAME_FIELD,
            RECEIVER_LAST_NAME_FIELD,
            RECEIVER_EMAIL_FIELD,
            MESSAGE_FIELD,
        ];

        const allFieldsFilled = requiredFields.every((field) => watch(field));

        setShowPaymentForm(allFieldsFilled);
    }, [watch()]);

    return (
        <div className={PAGE_CONTAINER}>
            <div className={SECTION_SUB_CONTAINER}>
                <PrismicRichText
                    field={data?.page_heading}
                    components={{
                        heading1: ({ children }) => (
                            <p className={PAGE_HEADING}>{children}</p>
                        ),
                    }}
                />
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div className={HEADING_CLASS}>
                        <PrismicRichText
                            field={data?.choose_gift_card_heading}
                            components={{
                                heading1: ({ children }) => <p>{children}</p>,
                            }}
                        />
                    </div>
                    <div className={FORM_AND_CARD_DESIGN_CONTAINER}>
                        <div className={IMAGE_SELECTOR_CONTAINER}>
                            <GiftCardDesignSection
                                heading={data?.choose_gift_card_heading}
                                items={items}
                                control={control}
                                setValue={setValue}
                            />
                        </div>
                        <GiftRecipientDetailsForm
                            control={control}
                            data={data}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            items={items}
                            GiftCardCurrencies={GiftCardCurrencies}
                        />
                    </div>
                    <div className={SENDER_DETAILS_FORM_CONTAINER}>
                        <GiftSenderDetailsForm
                            control={control}
                            data={data}
                            errors={errors}
                            register={register}
                            apiCall={GiftCardCallAPI}
                            isValid={isValid}
                            items={items}
                            watch={watch}
                            getCompleteData={getCompleteData}
                            showPaymentForm={showPaymentForm}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default GiftCardFormSection;
