import * as yup from "yup";
import { ARABIC } from "../../../public/Constants/EnumConstants";

export const GIFT_CARD_FORM_SCHEMA_EN = yup.object({
    gift_card_image: yup.string().required("Please Select an Image"),
    recipient_first_name: yup
        .string()
        .required("Please Enter Recipient's First Name"),
    recipient_last_name: yup
        .string()
        .required("Please Enter Recipient's Last Name"),
    recipient_email: yup
        .string()
        .email("Please Enter a Valid Email")
        .required("Please Enter Recipient's Email"),
    gift_card_amount: yup
        .number()
        .typeError("Please Select an Amount")
        .required("Please Select a Gift Card Amount"),
    gift_message: yup.string().required("Please Enter a Message for Recipient"),

    sender_full_name: yup.string().required("Please Enter your Name"),
    sender_email: yup
        .string()
        .email("Please Enter a Valid Email")
        .required("Please Enter Your Email"),
    date: yup
        .date()
        .typeError("Please Select a Date")
        .required("Please Select a Date"),
    time: yup
        .string()
        .typeError("Please Select a Time")
        .required("Please Select a Time"),
});

export const GIFT_CARD_FORM_SCHEMA_AR = yup.object({
    gift_card_image: yup.string().required("الرجاء تحديد صورة"),
    recipient_first_name: yup
        .string()
        .required("الرجاء إدخال الاسم الأول للمستلم"),
    recipient_last_name: yup
        .string()
        .required("الرجاء إدخال الاسم الأخير للمستلم"),
    recipient_email: yup
        .string()
        .email("يرجى إدخال البريد الإلكتروني الصحيح")
        .required("الرجاء إدخال البريد الإلكتروني للمستلم"),
    gift_card_amount: yup
        .number()
        .typeError("الرجاء تحديد مبلغ")
        .required("يرجى تحديد مبلغ بطاقة الهدايا"),
    gift_message: yup.string().required("الرجاء إدخال رسالة للمستلم"),

    sender_full_name: yup.string().required("من فضلك أدخل إسمك"),
    sender_email: yup
        .string()
        .email("يرجى إدخال البريد الإلكتروني الصحيح")
        .required("رجاءا أدخل بريدك الإلكتروني"),
    date: yup
        .date()
        .typeError("يرجى تحديد التاريخ")
        .required("يرجى تحديد التاريخ"),
    time: yup
        .string()
        .typeError("الرجاء تحديد الوقت")
        .required("الرجاء تحديد الوقت"),
});
export const getFormSchema = (locale) => {
    return locale === ARABIC
        ? GIFT_CARD_FORM_SCHEMA_AR
        : GIFT_CARD_FORM_SCHEMA_EN;
};
export const DEFAULT_VALUES = {
    gift_card_image: "",
    recipient_first_name: "",
    recipient_last_name: "",
    recipient_email: "",
    gift_card_amount: 0,
    gift_message: "",
    sender_full_name: "",
    sender_email: "",
    date: null,
    time: "",
};
