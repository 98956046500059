"use client";
import React, { useEffect, useState } from "react";
import PopUpLandingPage from "../../components/Molecules/PopUpLandingPage";
import { useDispatch, useSelector } from "react-redux";
import { setLoad, setVisibility } from "../../store/homePopupSlice";
import {
    ANIMATE_FADE_IN,
    ANIMATE_FADE_OUT,
    FADE_IN,
    FADE_OUT,
    FLEX,
    NONE,
} from "../../components/Molecules/PopUpLandingPage/styles";
import { BEFORE_UNLOAD } from "../../components/Molecules/PopUpLandingPage/constants";

const LandingPopup = ({ slice }) => {
    const [animation, setAnimation] = useState(FADE_IN);
    const { userData } = useSelector((state) => state.user);
    const { display, loaded } = useSelector((state) => state.popup);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            resetPopup();
        };

        window.addEventListener(BEFORE_UNLOAD, handleBeforeUnload);

        return () => {
            window.removeEventListener(BEFORE_UNLOAD, handleBeforeUnload);
        };
    }, []);

    const resetPopup = () => {
        dispatch(setLoad(false));
        dispatch(setVisibility(NONE));
    };

    const togglePopup = () => {
        setAnimation(FADE_OUT);
        setTimeout(() => {
            dispatch(setVisibility(NONE));
        }, 900);
    };

    useEffect(() => {
        if (!userData && !loaded) {
            setTimeout(() => {
                dispatch(setVisibility(FLEX));
                dispatch(setLoad(true));
            }, 3000);
        }
    }, []);

    return (
        <section
            className={
                animation === FADE_IN ? ANIMATE_FADE_IN : ANIMATE_FADE_OUT
            }
        >
            <PopUpLandingPage data={slice?.primary} onClick={togglePopup} />
            <style jsx>{`
                section {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.72),
                        rgba(255, 255, 255, 0.72)
                    );
                    z-index: 100;
                    display: ${display};
                    justify-content: center;
                    align-items: center;
                }
            `}</style>
        </section>
    );
};

export default LandingPopup;
