import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { LEFT_STYLING_SESSION_DESCRIPTION_CLASS } from "./constants";

const SessionDescription = ({ text }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <p
                        className={
                            " mt-2 sm:mt-3 mb-5 sm:mb-[23px] text-center text-lg sm:text-[17px] font-normal leading-tight tracking-tight font-Montserrat text-New_Primary_Default  "
                        }
                    >
                        {children}
                    </p>
                ),
                paragraph: ({ children }) => (
                    <p
                        className={
                            " mt-2 sm:mt-3 mb-5 sm:mb-[23px] text-center text-lg sm:text-[17px] font-normal leading-tight tracking-tight font-Montserrat text-New_Primary_Default  "
                        }
                    >
                        {children}
                    </p>
                ),
            }}
        ></PrismicRichText>
    );
};

export default SessionDescription;
