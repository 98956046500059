import CenterAlignedRoundLeftArrow from "../../Atoms/CarouselElements/CenterAlignedRoundLeftArrow/CenterAlignedRoundLeftArrow";
import CenterAlignedRoundRightArrow from "../../Atoms/CarouselElements/CenterAlignedRoundRightArrow/CenterAlignedRoundRightArrow";

export const settings = {
    dots: false,
    slidesToShow: 1,
    speed: 800,
    autoplaySpeed: 4000,
    cssEase: "linear",
    draggable: true,
    swipe: true,
    infinite: true,
    nextArrow: <CenterAlignedRoundRightArrow />,
    prevArrow: <CenterAlignedRoundLeftArrow />,
    arrows: true,
};

export const HOW_TO_WEAR_TAHT_INNER_CONTAINER_ONE =
    "max-w-full md:max-w-[100%]  mx-2 md:mx-auto mb-2 md:mb-10";
export const HOW_TO_WEAR_TAHT_INNER_CONTAINER_TWO =
    "howToWearThatCarousel max-w-full lg:max-w-[80%] mx-auto my-5 ";
export const TEXT_ALIGNMENT_CLASS_WITH_PADDING_TOP = "text-center pt-[3%]";
export const HOW_TO_WEAR_TAHT_HOMEPAGE_SECTION_BUTTON_ID_TEXT =
    "how-to-wearthat-homepage-section-button";
export const HOW_TO_WEAR_TAHT_SECTION_BUTTON_ID_TEXT =
    "how-to-wearthat-section-button";
