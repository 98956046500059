import NewCenterAlignedRoundLeftArrow from "../../Atoms/CarouselElements/NewCenterAlignedRoundLeftArrow";
import NewCenterAlignedRoundRightArrow from "../../Atoms/CarouselElements/NewCenterAlignedRoundRightArrow";

export const SETTINGS = {
    dots: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    draggable: true,
    swipe: true,
    infinite: true,
    arrows: true,
    nextArrow: <NewCenterAlignedRoundRightArrow />,
    prevArrow: <NewCenterAlignedRoundLeftArrow />,
};

export const NEWS_SECTION_CONTAINER_ONE = "max-w-full   mx-2 lg:mx-3";
export const NEWS_SECTION_CONTAINER_TWO =
    "new-home-slick-dot max-w-full 2xl:max-w-[80%] lg:max-w-[90%] mx-auto ";
