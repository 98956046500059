import { getCountryDetails } from "../../../public/Constants/CountriesList";
export const CARD_WRAPPER_CLASS = (orientation) => {
    if (orientation == "horizontal")
        return "flex flex-col items-center max-w-[319px] sm:max-w-[458px] pb-5 border-b-[1px] last:border-b-0 lg:border-b-0  border-New_Primary_Default even:lg:px-12 lg:[&:nth-child(3n+2)]:border-r-[1px]  lg:[&:nth-child(3n+2)]:border-l-[1px]";

    return "flex flex-col items-center max-w-[319px] sm:max-w-[458px] pb-5 border-b-[1px] last:border-b-0   border-New_Primary_Default";
};

export const CARD_INDEX_CLASS =
    "font-Montserrat font-normal text-[50px] sm:text-[62px] !font-extralight text-Pink_Rose leading-tight mb-2 sm:mb-4 ";
export const CARD_HEADING_CLASS = (haveButton) => {
    if (haveButton)
        return "sm:max-w-[300px] sm:h-[96px] font-Montserrat font-extralight text-[25px] sm:text-[35px] xl:text-[37px] leading-[30px] sm:leading-[43px] xl:leading-[49px]  sm:mb-4	";

    return "sm:max-w-[300px] sm:h-[96px] font-Montserrat font-extralight text-[25px] text-[20px] xl:text-[28px] leading-[30px] sm:leading-[43px] xl:leading-[49px]  sm:mb-4	";
};

export const CARD_DISCRIPTION_CLASS =
    "max-w-[280px] sm:max-w-[360px] font-Montserrat font-normal text-sm sm:text-xl !leading-6 text-center ";

export const updateDescription = (paragraph, userCountry) => {
    if (paragraph?.includes("SAR 107") && userCountry) {
        return paragraph?.replace(
            /SAR 107/g,
            `${userCountry?.currency} ${userCountry?.price}`
        );
    }
    return paragraph;
};
