import Image from "next/image";
import { PrismicImage } from "@prismicio/react";
import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { PinIcon } from "../../../public/Theme";
import {
    DISPLAYED_IMAGE,
    IMAGE_OPTIONS_CONTAINER,
    PIN_ICON_CONTAINER,
    SECTION_CONTAINER,
    SMALL_IMAGE_CLASS_CONTAINER,
    SELECTED_SMALL_IMAGE_CLASS_CONTAINER,
    SMALL_IMAGE_CLASS,
    IMAGE_FIELD_NAME,
    SECTION_SUB_CONTAINER,
} from "./constants";

const GiftCardDesignSection = ({ items, control, setValue }) => {
    const [selectedImage, setSelectedImage] = useState(
        items?.[0]?.template_text_image
    );
    const imageSelect = (image) => {
        setSelectedImage(image);
        setValue(IMAGE_FIELD_NAME, image?.url);
    };
    const selectedImageStatus = (imageUrl) => {
        return selectedImage?.url === imageUrl;
    };
    const pinIcon = (imageUrl) => {
        return selectedImageStatus(imageUrl) ? (
            <div className={PIN_ICON_CONTAINER}>
                <Image
                    width={40}
                    height={40}
                    src={PinIcon}
                    alt={"Pin Icon"}
                    loading="lazy"
                />
            </div>
        ) : null;
    };
    useEffect(() => {
        setSelectedImage(items?.[0]?.template_text_image);
        setValue(IMAGE_FIELD_NAME, items?.[0]?.template_text_image?.url);
    }, [items]);

    useEffect(() => {
        setSelectedImage(items?.[0]?.template_text_image);
        setValue(IMAGE_FIELD_NAME, items?.[0]?.template_text_image?.url);
    }, [items]);

    return (
        <div className={SECTION_CONTAINER}>
            <div className={SECTION_SUB_CONTAINER}>
                <div>
                    <Controller
                        name={IMAGE_FIELD_NAME}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: onChange, onBlur, value, name }) => (
                            <PrismicImage
                                field={selectedImage}
                                onBlur={onBlur}
                                className={DISPLAYED_IMAGE}
                            />
                        )}
                    />
                </div>
                <div className={IMAGE_OPTIONS_CONTAINER}>
                    {items?.map(
                        ({ template_image, template_text_image }, index) => {
                            return template_image?.url &&
                                template_text_image?.url ? (
                                <div
                                    className={
                                        selectedImageStatus(
                                            template_text_image?.url
                                        )
                                            ? SELECTED_SMALL_IMAGE_CLASS_CONTAINER
                                            : SMALL_IMAGE_CLASS_CONTAINER
                                    }
                                    key={index}
                                >
                                    {pinIcon(template_text_image?.url)}
                                    <PrismicImage
                                        onClick={() =>
                                            imageSelect(template_text_image)
                                        }
                                        field={template_image}
                                        key={index}
                                        className={SMALL_IMAGE_CLASS}
                                    />
                                </div>
                            ) : null;
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default GiftCardDesignSection;
