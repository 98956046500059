import React from "react";
import { CardNumber } from "frames-react";
import { INPUT_FIELD_CLASS } from "../../../../public/Constants/EnumConstants";
import { FIELD_CONTAINER } from "./constants";
import { InputLabelLight } from "../../Typography/InputLabel";

const CreditCardNumberField = ({ label }) => {
    return (
        <div className={FIELD_CONTAINER}>
            <InputLabelLight label={label} />
            <CardNumber className={INPUT_FIELD_CLASS} />
        </div>
    );
};

export default CreditCardNumberField;
