import React from "react";
import { MuteIcon, UnmuteIcon } from "../../../../public/Theme";
import Image from "next/image";

const VolumeIcon = ({ showButtons }) => {
    return (
        <>
            {showButtons?.muted ? (
                <Image
                    src={MuteIcon}
                    alt="mute-icon"
                    height={50}
                    width={50}
                    className="w-8 h-8 lg:w-10 lg:h-10"
                />
            ) : (
                <Image
                    src={UnmuteIcon}
                    alt="unmute-icon"
                    height={50}
                    width={50}
                    className="w-8 h-8 lg:w-10 lg:h-10"
                />
            )}
        </>
    );
};

export default VolumeIcon;
