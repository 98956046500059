import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { getStylingSessionDescriptionClass } from "./constants";

const StylingSessionDescription = ({ text, alignment }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <p className={getStylingSessionDescriptionClass(alignment)}>
                        {children}
                    </p>
                ),
                paragraph: ({ children }) => (
                    <p className={getStylingSessionDescriptionClass(alignment)}>
                        {children}
                    </p>
                ),
            }}
        ></PrismicRichText>
    );
};

export default StylingSessionDescription;
