// ApplePayButton.js
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSession } from "next-auth/react";
import Axios from "axios";
import { BACKEND_ENDPOINTS } from "../../../public/Constants/endpoints";
import { GIFT_CARD_SUCCESS_PAGE } from "../../../public/Constants/EnumConstants";

const MERCHANT_ID = process.env.NEXT_PUBLIC_MERCHANT_ID;
const BACKEND_URL_VALIDATE_SESSION =
    BACKEND_ENDPOINTS?.GIFT_CARD_VALIDATE_SESSION;
const BACKEND_URL_PAY = BACKEND_ENDPOINTS?.GIFT_APPLE_PAYMENT;

const ApplePayButton = ({ currency, country, disabled, completeData }) => {
    const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
    useEffect(() => {
        const checkApplePayAvailability = async () => {
            if (window?.ApplePaySession) {
                try {
                    const canMakePayments =
                        await ApplePaySession?.canMakePaymentsWithActiveCard(
                            MERCHANT_ID
                        );
                    setIsApplePayAvailable(canMakePayments);
                } catch (error) {
                    console.error(
                        "Error checking Apple Pay availability:",
                        error
                    );
                    Sentry.captureException(error);
                }
            }
        };
        checkApplePayAvailability();
    }, []);

    const getApplePaymentData = (currency, amount, country) => {
        // Implement precise decimal handling as discussed earlier
        // ...
        const applePaymentData = {
            countryCode: country,
            currencyCode: currency,
            supportedNetworks: ["visa", "masterCard", "amex", "discover"],
            merchantCapabilities: ["supports3DS"],
            lineItems: [
                {
                    label: "Gift Card",
                    amount: String(amount),
                },
            ],
            total: {
                label: "total",
                amount: String(parseInt(amount)),
            },
        };

        return applePaymentData;
    };

    const validateTheSession = async (validationURL) => {
        try {
            const response = await Axios.post(BACKEND_URL_VALIDATE_SESSION, {
                theValidationURL: validationURL,
            });
            return response.data;
        } catch (error) {
            toast("Failed to validate Apple Pay session.", { type: "error" });
            // throw error;
            console.log('//"Failed to validate Apple Pay session.', error);
            Sentry.captureException(error);
        }
    };

    const processPayment = async (applePaymentToken, completeData) => {
        try {
            const response = await Axios.post(BACKEND_URL_PAY, {
                ...completeData,
                token: applePaymentToken,

            });
            return response?.data || { success: true };
        } catch (error) {
            toast("Payment processing failed.", { type: "error" });
            // throw error;
            console.log("error in payment call", error);
            Sentry.captureException(error);
        }
    };

    const handleApplePayClick = async (currency, completeData, country) => {
        console.log('in handlePayment',completeData);
        const applePaymentData = getApplePaymentData(
            currency,
            completeData?.converted_amount,
            country
        );
        const applePaySession = new ApplePaySession(6, applePaymentData);

        applePaySession.onvalidatemerchant = async (event) => {
            try {
                const merchantSession = await validateTheSession(
                    event?.validationURL
                );
                applePaySession?.completeMerchantValidation(merchantSession);
            } catch (error) {
                applePaySession?.abort();
            }
        };

        applePaySession.onpaymentauthorized = async (event) => {
            try {
                const outcome = await processPayment(
                    event?.payment?.token,
                    completeData
                );
                console.log("//outcome", outcome);
                if (outcome) {
                    applePaySession?.completePayment(
                        ApplePaySession?.STATUS_SUCCESS
                    );
                    toast("Payment Successful", { type: "success" });

                    window.location.href = `/${GIFT_CARD_SUCCESS_PAGE}`;
                } else {
                    applePaySession?.completePayment(
                        ApplePaySession?.STATUS_FAILURE
                    );
                    toast("Payment Failed", { type: "error" });
                }
            } catch (error) {
                applePaySession?.completePayment(
                    ApplePaySession?.STATUS_FAILURE
                );
            }
        };

        applePaySession.oncancel = () => {
            toast("Apple Pay transaction was canceled.", { type: "info" });
        };

        applePaySession?.begin();
    };

    if (!isApplePayAvailable) return null;

    return (
        <button
            className="apple-pay-button-gift-card !w-[220px] "
            disabled={disabled}
            buttonstyle="black"
            text="Pay With"
            locale="en"
            onClick={() => {
                handleApplePayClick(currency, completeData, country);
            }}
        ></button>
    );
};

export default ApplePayButton;
