import React from "react";
import { useRouter } from "next/navigation";

import { HOW_IT_WORKS_ROW, SECTION_HEADING_CONTAINER } from "./constants";
import { getAlignment } from "../../utils/utils";
import HowItWorksCardVersion2 from "../HowItWorksCardVersion2";
import Montserrat40pxSemibold from "../../Atoms/Typography/Montserrat40pxSemibold";

const HowItWorksVersion2 = ({ title, items }) => {
    const router = useRouter();
    return (
        <>
            <div className={SECTION_HEADING_CONTAINER}>
                <Montserrat40pxSemibold text={title} />
            </div>
            <div className={HOW_IT_WORKS_ROW}>
                {items?.map((item, index) => (
                    <HowItWorksCardVersion2
                        item={item}
                        key={index}
                        index={index}
                        step={item?.stepnumber}
                        heading={item?.steptext}
                        description={item?.description}
                        image={item?.stepIcon}
                        imageAlt={item?.stepimage?.alt}
                        alignment={getAlignment(router?.locale)}
                        last={items?.length === index + 1}
                    />
                ))}
            </div>
        </>
    );
};

export default HowItWorksVersion2;
